const highlightChangedRow = (index) => {
  let highlightRowAgreement = document.getElementById(`${index}TrId`);
  highlightRowAgreement.className = "yellowElement";

  setTimeout(() => {
      highlightRowAgreement.className = "highlightElement";
  }, 1000);
};

export {
  highlightChangedRow
};
