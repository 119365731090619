import { useEffect } from 'react';
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { notificationsState, notificationsCountState } from "../atoms/notifications";
import envs from "../config";
import consumer from '../cable';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from "react-router-dom";


const useNotifications = () => {
  const setNotifications = useSetRecoilState(notificationsState);
  const setNotificationsCount = useSetRecoilState(notificationsCountState);
  const navigate = useNavigate();
  useEffect(() => {
    const channel = consumer.subscriptions.create({
      channel: 'JobsChannel',
    }, {
      connected: () => console.log("connected"),
      received: (data) => {
        fetchNotifications();
        NotificationManager.info('Los documentos han sido importados con éxito', 'La importación ha terminado', 5000, () => {
          navigate(`/search?logId=${data.notification.notifiable_id}`);
        });
      }
    })

    return () => {
      channel.unsubscribe();
    }
  }, []);

  const fetchNotifications = () => {
    axios.get(`${envs.backendUrl}/notifications`)
      .then(res => res.data)
      .then((notifications) => {
        let countNotif = 0
        notifications.sort(function (a, b) {
          return a.read - b.read
        });
        countNotif = notifications.filter(n => n.read === false)
        setNotifications(notifications);
        setNotificationsCount(countNotif.length);
      });
  }


  const markAsRead = (id) => {
    return axios.patch(`${envs.backendUrl}/notifications/${id}`)
      .then(() => {
        fetchNotifications();
      })
  }

  const markAllAsRead = () => {
    return axios.patch(`${envs.backendUrl}/notifications/read_all`)
      .then(() => {
        fetchNotifications();
      })
      .catch(error => {
        console.error('Error al marcar todas las notificaciones como leídas:', error);
      });
  }

  return {
    fetchNotifications,
    markAsRead,
    markAllAsRead
  }
}

export {
  useNotifications
}
