import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
    Button,
    Container,
    Table,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import {
    TiArrowSortedUp,
    TiArrowSortedDown,
    TiArrowUnsorted,
} from "react-icons/ti";
import "react-notifications/lib/notifications.css";
import "./styles/AgreementsPage.css";
import axios from "axios";
import envs from "../config";
import { Header } from "../components/Header";
import moment from "moment";
import { currentUserState } from '../atoms/users';
import { useRecoilValue } from 'recoil';
import { WithContext as ReactTags } from 'react-tag-input';
import { formatCurrency } from '../lib/formatCurrency';
import Tooltip from '@mui/material/Tooltip';
import warningIcon from './assets/warning-red.png';
import useAutocomplete from "../hooks/useAutocomplete";
import { FileUploader } from "react-drag-drop-files";

const delimiters = [13];
const fileTypes = ["JPG", "JPEG", "PNG", "PDF"];

function AgreementsPage() {
    const [agreementsData, setAgreementsData] = useState({});
    const [sortingColumn, setSortingColumn] = useState("");
    const [sortingDirection, setSortingDirection] = useState(true);
    const [enableDeleteButton, setEnableDeleteButton] = useState(false);
    const [checkboxesSelected, setCheckboxesSelected] = useState([]);
    const [allCheckboxesSelected, setAllCheckboxesSelected] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [deleteExpAndPaym, setDeleteExpAndPaym] = useState(true);
    const [valueIsChanging, setValueIsChanging] = useState(false);
    const currentUser = useRecoilValue(currentUserState);

    const [agreementPayerTags, setAgreementPayerTags] = useState([]);
    const [agreementPayerInputTags, setAgreementPayerInputTags] = useState([]);
    const [agreementVendorTags, setAgreementVendorTags] = useState([]);
    const [agreementVendorInputTags, setAgreementVendorInputTags] = useState([]);

    const [modalNewAgreement, setModalNewAgreement] = useState(false);
    const [modalImport, setModalImport] = useState(false);
    const [files, setFiles] = useState([]);
    const [notes, setNotes] = useState("");
    const [isCheck, setIsCheck] = useState(true);
    const [agreementFCCInput, setAgreementFCCInput] = useState('');
    const [agreementNoInput, setAgreementNoInput] = useState('');
    const [agreementCondInput, setAgreementCondInput] = useState('');
    const [agreementUniInput, setAgreementUniInput] = useState('');
    const [showCreateAgreementError, setShowCreateAgreementError] = useState(false);

    const { users, suggestions } = useAutocomplete();
    const suggestionsTags = suggestions.map(s => ({ ...s }));
    const autocompleteUsers = users.map(u => ({ ...u }));

    useEffect(() => {
        fetchAgreements();
        document.title = 'Expedientes'
    }, []);

    const fetchAgreements = () => {
        axios
            .get(`${envs.backendUrl}/agreements`)
            .then((res) => {
                res.data.agreements.forEach((element) => {
                    element.payers.forEach((payer) => {
                        payer.idUser = payer.id
                        payer.id = payer.name
                        payer.text = payer.name
                    })
                    element.vendors.forEach((vendor) => {
                        vendor.idUser = vendor.id
                        vendor.id = vendor.name
                        vendor.text = vendor.name
                    })
                    element["chkbxBool"] = false;
                });
                setAgreementsData(res.data.agreements);
            })
            .catch(function (e) {
                console.log(e);
            });
    };
    const sortColumnFunc = (col) => {
        let sortDirAux = sortingDirection;
        sortDirAux = sortingColumn !== col ? null : sortingDirection;
        if (sortDirAux === null) {
            setSortingDirection(true);
            setSortingColumn(col);
            sortDataFunc(col, true);
        } else if (sortDirAux) {
            setSortingDirection(false);
            setSortingColumn(col);
            sortDataFunc(col, false);
        } else {
            setSortingDirection(null);
            setSortingColumn("");
        }
    };

    const sortDataFunc = (col, direction) => {
        switch (col) {
            case 'created_at':
                agreementsData.sort(function (a, b) {
                    return !direction ? new Date(b[col]) - new Date(a[col]) : new Date(a[col]) - new Date(b[col])
                });
                break;

            default:
                agreementsData.sort(function (a, b) {
                    let aSort =
                        typeof a[col] === "string" ? a[col].toUpperCase() : a[col];
                    let bSort =
                        typeof b[col] === "string" ? b[col].toUpperCase() : b[col];

                    aSort = a[col] === "" ? null : aSort;
                    bSort = b[col] === "" ? null : bSort;

                    let comparison = 0;
                    if (aSort === null) {
                        comparison = 1;
                    } else if (bSort === null) {
                        comparison = -1;
                    } else if (aSort > bSort) {
                        comparison = 1;
                    } else if (aSort < bSort) {
                        comparison = -1;
                    }
                    return !direction ? comparison * -1 : comparison;
                });
                break;
        }
        setAgreementsData([...agreementsData]);
    };

    const changeCheckboxBool = (index) => {
        agreementsData[index].chkbxBool = !agreementsData[index].chkbxBool;
        setEnableDeleteButton(
            agreementsData.find((rD) => rD.chkbxBool === true)
                ? agreementsData.find((rD) => rD.chkbxBool === true).chkbxBool
                : false
        );
        setAgreementsData([...agreementsData]);
    };

    const selectAllChkbxs = (selected) => {
        agreementsData.forEach((aD) => {
            aD.chkbxBool = selected;
        });

        let chkbx = document.getElementsByName("chkbxInput");
        chkbx.forEach((cb) => {
            cb.checked = selected;
        });

        setEnableDeleteButton(selected);
        setAgreementsData([...agreementsData]);
        setAllCheckboxesSelected(selected);
    };

    const deselectAllChkbxs = () => {
        agreementsData.forEach((aD) => {
            aD.chkbxBool = false;
        });

        let chkbx = document.getElementsByName("chkbxInput");
        chkbx.forEach((cb) => {
            cb.checked = false;
        });

        setEnableDeleteButton(false);
        setAgreementsData([...agreementsData]);
        setAllCheckboxesSelected(false);
    };

    const openImportDataModal = (newAgreement) => {
        setModalImport(true)
        newAgreement && setModalNewAgreement(true)
    }

    const importData = () => {
        modalNewAgreement &&
            createAssociatePayments()
    }

    const createAssociatePayments = () => {
        if (agreementFCCInput.trim() === '') {
            NotificationManager.error('', 'Inserta un nombre para el expediente', 2000);
            return;
        }
        if (agreementNoInput.trim() === '') {
            NotificationManager.error('', 'Inserta un número de expediente', 2000);
            return;
        }
        else if (!agreementPayerTags || agreementPayerTags.length === 0) {
            NotificationManager.error('', 'Se debe seleccionar un comprador', 2000);
            return;
        }
        else if (!agreementVendorTags || agreementVendorTags.length === 0) {
            NotificationManager.error('', 'Se debe seleccionar un vendedor', 2000);
            return;
        } else if (files.length <= 0) {
            NotificationManager.info(
                'Debes adjuntar al menos 1 documento para hacer el proceso',
                'Adjunta documentos', 5000);
            return;
        }

        axios.post(`${envs.backendUrl}/agreements`, {
            agreement: {
                name: agreementFCCInput,
                sequence: agreementNoInput,
                vendor_user_ids: agreementVendorTags.map((aVT) => aVT.idUser),
                payer_user_ids: agreementPayerTags.map((aPT) => aPT.idUser),
                condominium: agreementCondInput,
                unit: agreementUniInput,
            }
        }).then(res => {
            if (res.status === 200 || res.status === 201) {
                let data = res.data
                saveFiles(true, data?.agreement?.id)
            } else {
                setShowCreateAgreementError(true)
            }
        }).catch(function (e) {
            console.log(e)
            e.response.status === 400 && setShowCreateAgreementError(true)
        })
    }

    const saveFiles = (haveAgreement, agSel) => {
        if (haveAgreement) {
            if (!agSel || agSel <= 0) {
                NotificationManager.error(
                    'Selecciona o crea un expediente',
                    'Error al importar archivos', 5000);
                return;
            }
        }
        if (files.length <= 0) {
            NotificationManager.info(
                'Debes adjuntar al menos 1 documento para hacer el proceso',
                'Adjunta documentos', 5000);
        } else {
            let formData = new FormData();
            files.forEach((f) =>
                formData.append('files[]', f)
            )
            formData.append('type', isCheck ? 'check' : 'document')
            formData.append('notes', notes);
            haveAgreement && formData.append('agreement_id', agSel);
            NotificationManager.info('', 'Los documentos están siendo importados, se le notificará cuando el proceso esté terminado', 5000);
            setFiles([])
            setNotes("");
            handleCloseImportModal()
            axios.post(`${envs.backendUrl}/api/v1/payments`, formData)
                .then(res => {
                    if (!(res.status === 200 || res.status === 201)) {
                        NotificationManager.error('Prueba más tarde', 'Ocurrió un error con la importación de los archivos', 2000);
                    }

                }).catch(function (e) {
                    console.log(e)
                })
        }
    }

    const handleCloseImportModal = () => {
        setModalImport(!modalImport)
        setModalNewAgreement(false)
        setNotes("")
        setFiles([])
        setIsCheck(true)
        setAgreementFCCInput('')
        setAgreementNoInput('')
        setAgreementCondInput('')
        setAgreementUniInput('')
        setAgreementPayerTags([])
        setAgreementVendorTags([])
        setShowCreateAgreementError(false)
    }

    const handleChange = (file) => {
        setFiles([...files, ...file])
    };

    const removeFileFromSelected = (index) => {
        let filesAux = files
        filesAux.splice(index, 1)
        setFiles([...filesAux])
    }

    const handleDeleteTagsAgreement = (tag, field) => {
        field === 'vendors' ?
            agreementVendorTags.splice(tag, 1)
            :
            agreementPayerTags.splice(tag, 1)
    }

    const fileTypeError = () => {
        NotificationManager.warning(
            'Los archivos no han sido importados debido a que el tipo de documento no es válido, los documentos deben tener la extensión jpeg, jpg, pdf o png',
            'Error en tipos de archivos', 5000);
    }

    const createNewAgreement = () => {
        return (
            <div>
                <h6>Crear expediente nuevo</h6>
                <Table borderless className='associatePaymentsTable'>
                    <tbody>
                        <tr>
                            <td>
                                <label>Expediente:</label>
                            </td>
                            <td>
                                <input value={agreementFCCInput} onChange={(e) => { setAgreementFCCInput(e.target.value); setShowCreateAgreementError(false) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>No. Expediente:</label>
                            </td>
                            <td>
                                <input value={agreementNoInput} onChange={(e) => { setAgreementNoInput(e.target.value); setShowCreateAgreementError(false) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Comprador:</label>
                            </td>
                            <td className='tagAgreement'>
                                {tagCompMultipleUser(agreementPayerTags, 'payers', undefined, true)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Vendedor:</label>
                            </td>
                            <td className='tagAgreement'>
                                {tagCompMultipleUser(agreementVendorTags, 'vendors', undefined, true)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Condominio:</label>
                            </td>
                            <td>
                                <input value={agreementCondInput} onChange={(e) => setAgreementCondInput(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Unidad:</label>
                            </td>
                            <td>
                                <input value={agreementUniInput} onChange={(e) => setAgreementUniInput(e.target.value)} />
                            </td>
                        </tr>
                    </tbody>
                </Table>

                {showCreateAgreementError &&
                    <label className='tdRed'>
                        El número de expediente {agreementNoInput} ya existe para este cliente
                    </label>
                }
            </div>
        )
    }

    const openDeleteModal = () => {
        let chkboxesAux = [];
        agreementsData.forEach((aD) => {
            aD?.chkbxBool && chkboxesAux.push(aD?.id);
        });
        setCheckboxesSelected(chkboxesAux);
        setModalDelete(true);
    };

    const deleteSelectedAgreements = () => {
        axios
            .delete(`${envs.backendUrl}/agreements/remove_multiple`, {
                data: {
                    ids: checkboxesSelected,
                    delete_payments: deleteExpAndPaym,
                },
            })
            .then((res) => {
                fetchAgreements();
                setModalDelete(false);
                deselectAllChkbxs()
                NotificationManager.success("", "Expedientes(s) eliminados", 2000);
            })
            .catch(function (e) {
                console.log(e);
                NotificationManager.error("", "Algo anda mal, prueba más tarde", 2000);
            });
    };

    const updateCell = (event, index, field) => {
        agreementsData[index][field] = event.target.value;
        setAgreementsData([...agreementsData]);
        setValueIsChanging(true);
    };

    const updateAgreementsData = (index, field, isChanging, tagVPdata) => {
        if (valueIsChanging || isChanging) {
            let bodyVal = {};

            bodyVal[field] = (
                field === 'vendor_user_ids' || field === 'payer_user_ids' ?
                    tagVPdata.map((rPV) => rPV.idUser)
                    :
                    agreementsData[index][field]
            );

            if (field !== 'condominium' && field !== 'unit' && field !== 'notes' && (bodyVal[field] === "" || bodyVal[field] === null)) {
                fetchAgreements();
            } else {
                const resData = axios
                    .put(`${envs.backendUrl}/agreements/${agreementsData[index].id}`, {
                        agreement: bodyVal,
                    })
                    .then(() => {
                        highlightChangedRow(index);
                        NotificationManager.success('', 'Cambio Guardado', 2000);
                        setValueIsChanging(false);
                    })
                    .catch(function (e) {
                        console.log(e);
                        if (e.response.data.message === 'Invalid agreement') {
                            NotificationManager.error(
                                'Ya existe ese número de expediente para este cliente',
                                'Error al actualizar expediente',
                                5000
                            )
                            fetchAgreements();
                            setValueIsChanging(false);
                        } else {
                            NotificationManager.error(
                                '',
                                'Algo anda mal, prueba más tarde',
                                2000
                            )
                        }
                    });
            }
        }
    };

    const blurOnEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    };

    const highlightChangedRow = (index) => {
        let highlightRowAgreement = document.getElementById(`${index}TrId`);
        highlightRowAgreement.className = "yellowElement";

        setTimeout(() => {
            highlightRowAgreement.className = "highlightElement";
        }, 1000);
    };

    const handleDeleteTags = (tag, index, field) => {
        if (agreementsData[index][field].length > 1) {
            agreementsData[index][field].splice(tag, 1)
            setAgreementsData([...agreementsData]);
            updateAgreementsData(index, field === 'vendors' ? 'vendor_user_ids' : 'payer_user_ids', true, agreementsData[index][field])
        } else {
            NotificationManager.error('', `El campo ${field === 'vendors' ? 'Vendedor' : 'Comprador'} no puede estar vacío`, 3000);
        }
    };

    const handleAdditionTags = (tag, index, field) => {
        let type = field === 'payers' ? 'payer_input' : 'vendor_input'
        if (agreementsData[index][type] !== '') {
            let existingTag = suggestionsTags.find((sT => sT.id === tag.id))
            if (existingTag) {
                insertUserIdToTag(tag, suggestionsTags.find(sT => sT.text === tag.text).id, index, field)
            } else {
                createNewUser(tag, index, field, false)
            }
        }
    };

    const handleAdditionTagsAgreement = (tag, field) => {
        if ((field === 'vendors' && agreementVendorInputTags !== '')
            || (field === 'payers' && agreementPayerInputTags !== '')) {
            let existingTag = suggestionsTags.find((sT => sT.id === tag.id))
            if (existingTag && existingTag.idUser > 0) {
                insertUserIdToTagAgreement(tag, autocompleteUsers.find(sT => sT.label === tag.text).value, field)
            } else {
                createNewUser(tag, undefined, field, true)
            }
        }
    }

    const insertUserIdToTagAgreement = (tag, userId, field) => {
        tag['idUser'] = userId

        if (field === 'vendors') {
            agreementVendorTags.push(tag)
            setAgreementVendorTags([...agreementVendorTags])
        } else {
            agreementPayerTags.push(tag)
            setAgreementPayerTags([...agreementPayerTags])
        }
    }

    const createNewUser = (tag, index, field, isAgreement) => {
        let body = { user: { name: tag.text } }
        axios.post(`${envs.backendUrl}/users`, body).then(res => {
            isAgreement ? insertUserIdToTagAgreement(tag, res.data?.id, field) : insertUserIdToTag(tag, res.data?.id, index, field)
        }).catch(function (e) {
            console.log(e)
        })
    }

    const insertUserIdToTag = (tag, userId, index, field) => {
        tag['idUser'] = userId

        agreementsData[index][field].push(tag)
        setAgreementsData([...agreementsData]);
        updateAgreementsData(index, field === 'vendors' ? 'vendor_user_ids' : 'payer_user_ids', true, agreementsData[index][field])
    }

    const sortButton = (colName) => {
        return (
            <Button className="clearBtn" onClick={() => sortColumnFunc(colName)}>
                {sortingColumn === colName ? (
                    sortingDirection ? (
                        <TiArrowSortedUp />
                    ) : (
                        <TiArrowSortedDown />
                    )
                ) : (
                    <TiArrowUnsorted />
                )}
            </Button>
        );
    };

    const inputEditableCell = (val, index, field) => {
        return (
            <input
                value={val}
                onChange={(e) => updateCell(e, index, field)}
                onKeyUp={(e) => blurOnEnter(e)}
                onBlur={() => updateAgreementsData(index, field)}
            />
        );
    };

    const tagCompMultipleUser = (data, field, index, isAgreement) => {
        return (
            <ReactTags
                classNames={{
                    tagInputField: 'tagInputClassKey',
                }}
                tags={data}
                suggestions={suggestionsTags}
                placeholder={`Presiona enter para agregar ${field === 'payers' ? 'comprador' : 'vendedor'}`}
                allowDragDrop={false}
                delimiters={delimiters}
                handleDelete={(tag) => { isAgreement ? handleDeleteTagsAgreement(tag, field) : handleDeleteTags(tag, index, field) }}
                handleAddition={(tag) => { isAgreement ? handleAdditionTagsAgreement(tag, field) : handleAdditionTags(tag, index, field) }}
                inputFieldPosition='top'
                autocomplete
                autofocus={false}
                allowDeleteFromEmptyInput={false}
                minQueryLength={1}
                handleInputChange={(k) => { }}
            />);
    }

    return (
        <>
            <Header />
            <Container className="containerAgreementsPage">
                <h4 className="h4Imp">Expedientes</h4>
                <div className="deleteAgreementsContainer" style={agreementsData.length > 0 ? {} : { justifyContent: 'center' }}>
                    {agreementsData.length > 0 && (<Button
                        disabled={!enableDeleteButton}
                        onClick={() => openDeleteModal()}
                    >
                        Eliminar
                    </Button>)}
                    <Button className='mx-1' color='primary' onClick={() => openImportDataModal(true)}>Expediente Nuevo</Button>
                </div>
                {agreementsData.length > 0 && (
                    <>
                        <Table bordered striped className="tableAgreements">
                            <thead>
                                <tr>
                                    <th>
                                        <input
                                            value={allCheckboxesSelected}
                                            onChange={(e) => selectAllChkbxs(e.target.checked)}
                                            type={"checkbox"}
                                        />
                                    </th>
                                    <th>Expediente{sortButton("name")}</th>
                                    <th>Número{sortButton("sequence")}</th>
                                    <th>Comprador</th>
                                    <th>Vendedor</th>
                                    <th>Total Pesos</th>
                                    <th>Total Dolares</th>
                                    <th>Condominio{sortButton("condominium")}</th>
                                    <th>Unidad{sortButton("unit")}</th>
                                    <th>Fecha de creación{sortButton("created_at")}</th>
                                    <th>Notas{sortButton("notes")}</th>

                                    {currentUser.user_role == 'admin' && <th>Usuario</th>}
                                    <th>Pagos</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {agreementsData.map((aD, index) => (
                                    <tr key={index} id={`${index}TrId`}>
                                        <td>
                                            <input
                                                name="chkbxInput"
                                                value={aD?.chkbxBool}
                                                onChange={() => changeCheckboxBool(index)}
                                                type={"checkbox"}
                                            />
                                        </td>
                                        <td>
                                            {inputEditableCell(
                                                aD?.name === null ? "Desconocido" : aD?.name,
                                                index,
                                                "name"
                                            )}
                                        </td>
                                        <td>
                                            {inputEditableCell(
                                                aD.sequence === null ? "-" : aD.sequence,
                                                index,
                                                "sequence"
                                            )}
                                        </td>
                                        <td>
                                            {tagCompMultipleUser(aD?.payers, 'payers', index, false)}
                                        </td>
                                        <td>
                                            {tagCompMultipleUser(aD?.vendors, 'vendors', index, false)}
                                        </td>
                                        <th>{formatCurrency(aD?.total_mxn)}</th>
                                        <th>{formatCurrency(aD?.total_usd)}</th>
                                        <td>
                                            {inputEditableCell(
                                                aD?.condominium === null ? "" : aD?.condominium,
                                                index,
                                                "condominium"
                                            )}
                                        </td>
                                        <td>
                                            {inputEditableCell(
                                                aD?.unit === null ? "" : aD?.unit,
                                                index,
                                                "unit"
                                            )}
                                        </td>
                                        <td>
                                            <label>{moment(aD?.created_at).format('DD/MM/yyyy')}</label>
                                        </td>
                                        <td>
                                            {inputEditableCell(
                                                aD?.notes === null ? "" : aD?.notes,
                                                index,
                                                "notes"
                                            )}
                                        </td>
                                        {currentUser.user_role === 'admin' && <td>{aD?.created_by.email}</td>}
                                        <td>
                                            {parseInt(aD.docs_count) > 0 ? <a href={`/search?agreementId=${aD?.id}`}>Ver ({aD?.docs_count})</a> : `No tiene pagos`}
                                        </td>
                                        <td>
                                            {aD?.exceeds_cash_amount ?
                                                <Tooltip followCursor arrow title={<label>Excede Efectivo</label>}>
                                                    <div>
                                                        <img src={warningIcon} alt="" style={{ width: '20px', marginBottom: '4px' }} />
                                                        Excede efectivo
                                                    </div>
                                                </Tooltip>
                                                :
                                                '-'
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                )}
                <Modal
                    isOpen={modalDelete}
                    toggle={() => setModalDelete(!modalDelete)}
                    className="modalDeleteExpAndPaym"
                >
                    <ModalHeader toggle={() => setModalDelete(!modalDelete)}>
                        Confirmación de eliminación de Expediente(s)
                    </ModalHeader>
                    <ModalBody>
                        <Container className="modalDeleteContainer">
                            <h6>Selecciona la acción que deseas ejecutar: </h6>
                            <div onClick={() => setDeleteExpAndPaym(!deleteExpAndPaym)}>
                                <input
                                    type={"checkbox"}
                                    checked={deleteExpAndPaym}
                                    onChange={() => setDeleteExpAndPaym(!deleteExpAndPaym)}
                                />
                                <label>
                                    Eliminar expediente(s) y todos los pagos asociados
                                </label>
                            </div>
                            <div onClick={() => setDeleteExpAndPaym(!deleteExpAndPaym)}>
                                <input
                                    type={"checkbox"}
                                    checked={!deleteExpAndPaym}
                                    onChange={() => setDeleteExpAndPaym(!deleteExpAndPaym)}
                                />
                                <label>
                                    Eliminar sólo expediente(s) y dejar pagos con expediente vacío
                                </label>
                            </div>
                        </Container>
                    </ModalBody>
                    <ModalFooter className="footerDeleteExpAndPaym">
                        <Button color="danger" onClick={() => deleteSelectedAgreements()}>
                            Eliminar
                        </Button>
                        <Button onClick={() => setModalDelete(!modalDelete)}>
                            Cancelar
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={modalImport} toggle={() => handleCloseImportModal()}>
                    <ModalHeader toggle={() => handleCloseImportModal()}>Importar</ModalHeader>
                    <ModalBody>
                        <Container className=''>
                            {modalNewAgreement && createNewAgreement()}
                            <h6 className='h6TitleSearch'>
                                Arrastre los documentos al área para que sean procesados,
                                las extensiones válidas son jpg, jpeg, png y pdf
                            </h6>
                            <div className='fileUploaderDivSearch'>
                                <FileUploader handleChange={handleChange} name="file"
                                    types={fileTypes} multiple={true} onTypeError={() => fileTypeError()} />
                                <div className='checkboxContainer'>
                                    <div onClick={() => setIsCheck(!isCheck)}>
                                        <input type={'checkbox'} checked={isCheck} onChange={() => setIsCheck(!isCheck)} />
                                        <label>Cheques</label>
                                    </div>
                                    <div onClick={() => setIsCheck(!isCheck)}>
                                        <input type={'checkbox'} checked={!isCheck} onChange={() => setIsCheck(!isCheck)} />
                                        <label>Recibos</label>
                                    </div>
                                </div>
                                <div className={'notesImport'}>
                                    <input
                                        placeholder="Notas"
                                        onChange={(e) => setNotes(e.target.value)}
                                        value={notes}
                                    />
                                </div>
                            </div>
                            {files && files.length > 0 &&
                                <div className='selectedFilesDiv'>
                                    <ul className='ulFiles'>
                                        {files.map((f, index) => (
                                            <li key={index} className='liFiles'>
                                                <label>{f.name}</label>
                                                <Button color='danger' onClick={() => removeFileFromSelected(index)}>Remove</Button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </Container>
                    </ModalBody>
                    <ModalFooter className='exportButtonFooter'>
                        <Button color='primary' onClick={() => importData()}>Importar</Button>
                        <Button onClick={() => handleCloseImportModal()}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </Container>

            <NotificationContainer />
        </>
    );
}

export default AgreementsPage;
