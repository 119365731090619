import { useEffect } from 'react';
import envs from '../config';
import axios from 'axios';
import { autocompleteState } from '../atoms/autocomplete';
import { useRecoilState } from 'recoil';

const useAutocomplete = () => {
  useEffect(() => {
    autocompleteSearch();
  }, [])

  const [autocomplete, setAutocomplete] = useRecoilState(autocompleteState);

  const autocompleteSearch = () => {
    if (autocomplete.users.length && autocomplete.suggestions.length) {
      return;
    }

    axios.get(`${envs.backendUrl}/users`, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      const dataAux = res.data.map(d => ({ value: d.id, label: d.name }));
      const suggestionsTags = res.data.map((d) => ({ id: `${d.id}`, text: d.name, idUser: d.id }))

      localStorage.setItem('autocompleteUsers', encodeURI(JSON.stringify(dataAux)));
      localStorage.setItem('suggestionTags', encodeURI(JSON.stringify(suggestionsTags)))

      setAutocomplete({ users: dataAux, suggestions: suggestionsTags });
    }).catch(function (e) {
      console.log(e)
    })
  }

  return {
    users: autocomplete.users,
    suggestions: autocomplete.suggestions
  }
}

export default useAutocomplete;
