import warningIcon from './assets/warning.png';
import { Button, Container, Table, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import './styles/SearchUserData.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ImWarning } from "react-icons/im";
import { TiArrowSortedUp, TiArrowSortedDown, TiArrowUnsorted } from "react-icons/ti";
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Pagination from '@mui/material/Pagination';
import envs from '../config';
import moment from 'moment';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Header } from '../components/Header';
import { formatCurrency, unformatCurrency } from '../lib/formatCurrency';
import { WithContext as ReactTags } from 'react-tag-input';
import { currentUserState } from '../atoms/users';
import { useRecoilValue } from 'recoil';
import PaymentDatePicker from '../components/PaymentDatePicker';
import AmountField from '../components/AmountField';
import { TableContext } from '../context'
import { FileUploader } from "react-drag-drop-files";
import useAutocomplete from '../hooks/useAutocomplete';
import { SortButton } from '../components/SortButton';

import "react-datepicker/dist/react-datepicker.css";

const fileTypes = ["JPG", "JPEG", "PNG", "PDF"];
const colourStyles = {
    singleValue: (styles) => ({
        ...styles,
        textAlign: 'left',
        color: 'rgb(120,120,120)',
    }),
    option: (styles) => {
        return {
            ...styles,
            textAlign: 'left',
        };
    },
};

const delimiters = [13];

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 'none',
    },
});

function SearchUserData() {

    const currentUser = useRecoilValue(currentUserState);
    const [modalDelete, setModalDelete] = useState(false);
    const [modalExport, setModalExport] = useState(false);
    const [modalImport, setModalImport] = useState(false);
    const [modalNewAgreement, setModalNewAgreement] = useState(false);
    const [exportOrder, setExportOrder] = useState('asc');
    const [addDOF, setAddDOF] = useState(false);
    const [selectedData, setSelectedData] = useState();
    const [receiptData, setReceiptData] = useState({});
    const [clearTimeoutReference, setClearTimeoutReference] = useState();
    const [totalMXNChecks, setTotalMXNChecks] = useState(0);
    const [totalMXNTransf, setTotalMXNTransf] = useState(0);
    const [totalMXNCash, setTotalMXNCash] = useState(0);
    const [totalMXN, setTotalMXN] = useState(0);
    const [totalUSDChecks, setTotalUSDChecks] = useState(0);
    const [totalUSDTransf, setTotalUSDTransf] = useState(0);
    const [totalUSDCash, setTotalUSDCash] = useState(0);
    const [totalUSD, setTotalUSD] = useState(0);
    const [totalMXNCashExceed, setTotalMXNCashExceed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isCellUpdating, setIsCellUpdating] = useState(false);
    const [agreementCheckboxArr, setAgreementCheckboxArr] = useState([]);
    const [enableAgreementModalButton, setEnableAgreementModalButton] = useState(false);
    const [allCheckboxesSelected, setAllCheckboxesSelected] = useState(true);
    const [isAssociateUseState, setIsAssociateUseState] = useState(false);

    const [sortingColumn, setSortingColumn] = useState(null);
    const [sortingDirection, setSortingDirection] = useState(null);

    const [logIdBool, setLogIdBool] = useState(false);
    const [agreementIdBool, setAgreementIdBool] = useState(false);
    const [personId, setPersonId] = useState(null);

    const [agreementPayerTags, setAgreementPayerTags] = useState([]);
    const [agreementPayerInputTags, setAgreementPayerInputTags] = useState([]);
    const [agreementVendorTags, setAgreementVendorTags] = useState([]);
    const [agreementVendorInputTags, setAgreementVendorInputTags] = useState([]);

    const [paginationCount, setPaginationCount] = useState(1);
    const [paginationTotal, setPaginationTotal] = useState(0);
    const [pagination, setPagination] = useState({ count: 0 });

    const [files, setFiles] = useState([]);
    const [notes, setNotes] = useState("");
    const [isCheck, setIsCheck] = useState(true);
    const [agreementFCCInput, setAgreementFCCInput] = useState('');
    const [agreementNoInput, setAgreementNoInput] = useState('');
    const [agreementCondInput, setAgreementCondInput] = useState('');
    const [agreementUniInput, setAgreementUniInput] = useState('');
    const [showCreateAgreementError, setShowCreateAgreementError] = useState(false);
    const [paymentTotals, setPaymentTotals] = useState();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const { users, suggestions } = useAutocomplete();
    const autocompleteUsers = users.map(u => ({ ...u }));
    const suggestionsTags = suggestions.map(s => ({ ...s }));

    useEffect(() => {
        if (localStorage.getItem('token') != null) {
            axios
                .get(`${envs.backendUrl}/login`, {
                    headers: {
                        Authorization: localStorage.token,
                    },
                })
                .then((resp) => resp.data)
                .then((user) => {
                    localStorage.setItem('user_id', user.id);
                });
        } else {
            window.location = '/'
        }
    }, [])

    useEffect(() => {
        document.title = 'Pagos'
    }, [])

    useEffect(() => {
        urlParams.get('logId') && setLogIdBool(true)
        urlParams.get('agreementId') && setAgreementIdBool(true)
        urlParams.get('personId') && setPersonId(urlParams.get("personId"))
    }, [])

    useEffect(() => {
        if (logIdBool || agreementIdBool || personId) {
            getReceipts(false)
        }
    }, [logIdBool, agreementIdBool, personId])

    useEffect(() => {
        if (receiptData.length > 0) {
            totalSummatory()
            document.title = receiptData[0].agreement_name ? `${receiptData[0].agreement_name} (${receiptData[0].agreement_sequence})` : 'Pagos'
        }
    }, [receiptData])

    useEffect(() => {
        if (paginationCount > 0 && receiptData.length > 0) {
            getReceipts(false)
        }
    }, [paginationCount])

    useEffect(() => {
        if (sortingDirection && sortingColumn)
            getReceipts(false)
    }, [sortingDirection, sortingColumn]);

    const getReceipts = (isUpdatedRows, userName, rows, isAssociate, viewAllPayments) => {
        setIsAssociateUseState(isAssociate ? true : false)
        setSelectedData(userName)

        let url = `${envs.backendUrl}/receipts?`
        if (isAssociate) {
            url += `search_type=agreement&search_value=${userName}`
        } else if (userName !== undefined) {
            url += `search_type=person&search_value=${userName}`
        } else if (personId) {
            url += `search_type=person_id&search_value=${personId}`
        } else if (viewAllPayments) {
            url += `search_type=all`
        } else if (logIdBool) {
            url += `search_type=job&search_value=${urlParams.get('logId')}`
        } else if (agreementIdBool) {
            url += `search_type=agreement&search_value=${urlParams.get('agreementId')}`
        }

        if (sortingColumn && sortingDirection) {
            url += `&sort_field=${sortingColumn}&sort_direction=${sortingDirection}`
        } else {
            url += `&sort_field=payment_day&sort_direction=asc`
        }

        return axios.get(`${url}&page=${paginationCount === 0 ? '1' : paginationCount}`, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(res => {
                paginationCount === 0 && setPaginationCount(1)
                let responseData = res.data
                setPaymentTotals(res.data.payment_totals)
                responseData.data.forEach(element => {
                    element.payers.forEach((payer) => {
                        payer.idUser = payer.id
                        payer.id = payer.name
                        payer.text = payer.name
                    })
                    element.vendors.forEach((vendor) => {
                        vendor.idUser = vendor.id
                        vendor.id = vendor.name
                        vendor.text = vendor.name
                    })
                    element['agreementBool'] = false
                });
                setReceiptData(responseData?.data)
                setPaginationTotal(responseData?.pagination?.pages)
                setPagination(responseData?.pagination)
                setIsLoading(false)
                setAgreementCheckboxArr([])
                if (isUpdatedRows) {
                    highlightChangedRows(rows);
                    NotificationManager.success('', 'Cambio Guardado', 2000);
                }

            }).catch(function (e) {
                console.log(e)
                setIsLoading(false)
            })
    }

    const openImportDataModal = (newAgreement) => {
        setModalImport(true)
        newAgreement && setModalNewAgreement(true)
    }

    const handleChange = (file) => {
        setFiles([...files, ...file])
    };

    const removeFileFromSelected = (index) => {
        let filesAux = files
        filesAux.splice(index, 1)
        setFiles([...filesAux])
    }

    const fileTypeError = () => {
        NotificationManager.warning(
            'Los archivos no han sido importados debido a que el tipo de documento no es válido, los documentos deben tener la extensión jpeg, jpg, pdf o png',
            'Error en tipos de archivos', 5000);
    }

    const importData = () => {
        modalNewAgreement ?
            createAssociatePayments()
            :
            saveFiles(true, receiptData[0]?.agreement_id)
    }

    const handleCloseImportModal = () => {
        setModalImport(!modalImport)
        setModalNewAgreement(false)
        setNotes("")
        setFiles([])
        setIsCheck(true)
        setAgreementFCCInput('')
        setAgreementNoInput('')
        setAgreementCondInput('')
        setAgreementUniInput('')
        setAgreementPayerTags([])
        setAgreementVendorTags([])
        setShowCreateAgreementError(false)
    }

    const createAssociatePayments = () => {
        if (agreementFCCInput.trim() === '') {
            NotificationManager.error('', 'Inserta un nombre para el expediente', 2000);
            return;
        }
        if (agreementNoInput.trim() === '') {
            NotificationManager.error('', 'Inserta un número de expediente', 2000);
            return;
        }
        else if (!agreementPayerTags || agreementPayerTags.length === 0) {
            NotificationManager.error('', 'Se debe seleccionar un comprador', 2000);
            return;
        }
        else if (!agreementVendorTags || agreementVendorTags.length === 0) {
            NotificationManager.error('', 'Se debe seleccionar un vendedor', 2000);
            return;
        } else if (files.length <= 0) {
            NotificationManager.info(
                'Debes adjuntar al menos 1 documento para hacer el proceso',
                'Adjunta documentos', 5000);
            return;
        }

        axios.post(`${envs.backendUrl}/agreements`, {
            agreement: {
                name: agreementFCCInput,
                sequence: agreementNoInput,
                vendor_user_ids: agreementVendorTags.map((aVT) => aVT.idUser),
                payer_user_ids: agreementPayerTags.map((aPT) => aPT.idUser),
                condominium: agreementCondInput,
                unit: agreementUniInput,
            }
        }).then(res => {
            if (res.status === 200 || res.status === 201) {
                let data = res.data
                saveFiles(true, data?.agreement?.id)
            } else {
                setShowCreateAgreementError(true)
            }
        }).catch(function (e) {
            console.log(e)
            e.response.status === 400 && setShowCreateAgreementError(true)
        })
    }

    const saveFiles = (haveAgreement, agSel) => {
        if (haveAgreement) {
            if (!agSel || agSel <= 0) {
                NotificationManager.error(
                    'Selecciona o crea un expediente',
                    'Error al importar archivos', 5000);
                return;
            }
        }
        if (files.length <= 0) {
            NotificationManager.info(
                'Debes adjuntar al menos 1 documento para hacer el proceso',
                'Adjunta documentos', 5000);
        } else {
            let formData = new FormData();
            files.forEach((f) =>
                formData.append('files[]', f)
            )
            formData.append('type', isCheck ? 'check' : 'document')
            formData.append('notes', notes);
            haveAgreement && formData.append('agreement_id', agSel);
            NotificationManager.info('', 'Los documentos están siendo importados, se le notificará cuando el proceso esté terminado', 5000);
            setFiles([])
            setNotes("");
            handleCloseImportModal()
            axios.post(`${envs.backendUrl}/api/v1/payments`, formData)
                .then(res => {
                    if ((res.status === 200 || res.status === 201)) {
                        getReceipts(false)
                    } else {
                        NotificationManager.error('Prueba más tarde', 'Ocurrió un error con la importación de los archivos', 2000);
                        setIsLoading(false)
                    }

                }).catch(function (e) {
                    console.log(e)
                })
        }
    }

    const openModalDelete = () => {
        setModalDelete(true)
        let agreementAux = []
        receiptData.forEach((rD) => {
            rD?.agreementBool && agreementAux.push(rD?.id)
        })
        setAgreementCheckboxArr(agreementAux)
    }

    const updateCell = (event, index, field) => {
        setIsCellUpdating(true)
        receiptData[index][field] = event.target.value;

        setReceiptData([...receiptData])
        if (field === 'payment_type' || field === 'currency') {
            updateReceiptData(index, field, true)
        }
    }

    const updateField = (value, index, field) => {
        setIsCellUpdating(true);
        receiptData[index][field] = value;
        setReceiptData([...receiptData])
        return updateReceiptData(index, field, true);
    }

    const blurOnEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            e.target.blur();
        }
    }

    const totalSummatory = () => {
        let tMXN = 0;
        let tMXNc = 0;
        let tMXNe = 0;
        let tMXNt = 0;
        let tUSD = 0;
        let tUSDc = 0;
        let tUSDe = 0;
        let tUSDt = 0;
        receiptData.forEach((rData) => {
            if (rData?.amount && rData?.amount !== '' && rData?.amount !== 0 && rData?.amount !== null && !isNaN(rData?.amount)) {
                const summablePaymentTypes = ['cheque', 'efectivo', 'transferencia'];
                if (rData?.currency === 'mxn') {
                    if (rData?.payment_type === 'cheque') {
                        tMXNc = tMXNc + parseFloat(rData?.amount)
                    } else if (rData?.payment_type === 'efectivo') {
                        tMXNe = tMXNe + parseFloat(rData?.amount)
                    } else if (rData?.payment_type === 'transferencia') {
                        tMXNt = tMXNt + parseFloat(rData?.amount)
                    }
                    if (summablePaymentTypes.includes(rData.payment_type)) { tMXN += parseFloat(rData.amount) }
                } else if (rData?.currency === 'usd') {
                    if (rData?.payment_type === 'cheque') {
                        tUSDc = tUSDc + parseFloat(rData?.amount)
                    } else if (rData?.payment_type === 'efectivo') {
                        tUSDe = tUSDe + parseFloat(rData?.amount)
                    } else if (rData?.payment_type === 'transferencia') {
                        tUSDt = tUSDt + parseFloat(rData?.amount)
                    }
                    if (summablePaymentTypes.includes(rData.payment_type)) { tUSD += parseFloat(rData.amount) }
                }
            }
        })
        setTotalMXN(tMXN.toFixed(2))
        setTotalMXNChecks(tMXNc.toFixed(2))
        setTotalMXNCash(tMXNe.toFixed(2))
        !totalMXNCashExceed && tMXNe.toFixed(2) > 832513.50 && NotificationManager.error('', 'Pago en efectivo excedido', 2000);
        setTotalMXNCashExceed(tMXNe.toFixed(2) > 832513.50)
        setTotalMXNTransf(tMXNt.toFixed(2))
        setTotalUSD(tUSD.toFixed(2))
        setTotalUSDChecks(tUSDc.toFixed(2))
        setTotalUSDCash(tUSDe.toFixed(2))
        setTotalUSDTransf(tUSDt.toFixed(2))
    }

    const updateReceiptData = (index, type, updating, tagVPdata) => {
        if (updating || isCellUpdating) {
            let receiptRow = receiptData[index]
            let bodyVal
            switch (type) {
                case 'currency':
                    bodyVal = receiptRow.currency !== '-' && receiptRow.currency !== 'desconocido' && receiptRow.currency
                    break;

                case 'payment_type':
                    bodyVal = receiptRow.payment_type !== '-' && receiptRow.payment_type
                    break;

                case 'payer_user_ids':
                case 'vendor_user_ids':
                    bodyVal = tagVPdata.map((rPV) => rPV.idUser)
                    break;

                default:
                    bodyVal = receiptRow[type]
                    break;
            }

            let payment = {}
            payment[type] = bodyVal
            return axios.put(`${envs.backendUrl}/receipts/${receiptRow.id}`, {
                payment: payment
            }).then(res => {
                getReceipts(true, selectedData, (res.data.rows.length > 0 ? res.data.rows : [receiptRow.id]), isAssociateUseState)
                setIsCellUpdating(false)
                return res;
            }).catch(function (e) {
                console.log(e)
            })
        }
    }

    const highlightChangedRows = (rows) => {
        rows.forEach((r) => {
            let highlightReceipt = document.getElementById(`trReceipt${r}`);
            highlightReceipt.className = 'yellowElement'
        })
        setTimeout(() =>
            rows.forEach((r) => {
                let highlightReceipt = document.getElementById(`trReceipt${r}`);
                highlightReceipt.className = 'highlightElement'
            }), 1000)

        setTimeout(() => {
            let highlightedElements = document.getElementsByClassName('yellowElement')
            Array.from(highlightedElements).forEach(function (element) {
                element.classList.remove('yellowElement')
                element.classList.remove('highlightElement')
            });
        }, 2000)
    }

    const changeCheckboxAgreement = (index) => {
        receiptData[index].agreementBool = !receiptData[index].agreementBool;
        setEnableAgreementModalButton(receiptData.find(rD => rD.agreementBool === true) ? receiptData.find(rD => rD.agreementBool === true).agreementBool : false)
        setReceiptData([...receiptData])
        if (receiptData[index].agreementBool) {
            setAgreementCheckboxArr([...agreementCheckboxArr, receiptData[index].id])
        } else {
            let ind = agreementCheckboxArr.findIndex(aCA => aCA === receiptData[index].id)
            agreementCheckboxArr.splice(ind, 1)
            setAgreementCheckboxArr([...agreementCheckboxArr])

        }
    }

    const changeAllCheckboxAgreement = (sel) => {
        let ids = []
        receiptData.map(rD => {
            rD.agreementBool = sel
            sel && ids.push(rD.id)
        })

        setAgreementCheckboxArr(ids)
        let chkbx = document.getElementsByName('chkbx')
        chkbx.forEach((cb) => {
            cb.checked = sel
        })

        setEnableAgreementModalButton(sel)
        setReceiptData([...receiptData])
        setAllCheckboxesSelected(sel)
    }

    const deleteSelectedPayments = () => {
        axios.delete(`${envs.backendUrl}/payments/remove_multiple`, { data: { ids: agreementCheckboxArr } }
        ).then(res => {
            getReceipts(false, selectedData)
            setAgreementCheckboxArr([])

            setAllCheckboxesSelected(false)
            setEnableAgreementModalButton(false)

            NotificationManager.success('', 'Recibo(s) eliminados', 2000);
        }).catch(function (e) {
            console.log(e)
            NotificationManager.error('', 'Algo anda mal, prueba más tarde', 2000);
        })
        setModalDelete(false)
    }

    const exportData = () => {
        let url = `${envs.backendUrl}/receipts.csv?order=${exportOrder}&`
        let nameExport = `${receiptData[0].agreement_name} (${receiptData[0].agreement_sequence}) `
        if (isAssociateUseState) {
            url += `search_type=agreement&search_value=${selectedData}`
            nameExport += '-'
        } else if (selectedData !== undefined) {
            url += `search_type=person&search_value=${selectedData}`
            nameExport += 'person_'
        }
        else if (logIdBool) {
            url += `search_type=job&search_value=${urlParams.get('logId')}`
            nameExport += 'import_'
        } else if (agreementIdBool) {
            url += `search_type=agreement&search_value=${urlParams.get('agreementId')}`
            nameExport += '-'
        } else {
            url += `search_type=all`
            nameExport += 'all_'
        }
        nameExport += addDOF ? 'DOF_' : ''
        nameExport += moment().format('DD/MM/yyyy')
        url += `&include_exchange_rate=${addDOF}`
        axios({
            url: url,
            method: 'GET',
            responseType: 'blob'
        }).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${nameExport}.csv`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        })
        setAddDOF(false)
        setModalExport(false)
    }

    const openExportDataModal = () => {
        setModalExport(true)
    }

    const handleChangeInputTags = (input, index, field, isAgreement) => {
        if (isAgreement) {
            field === 'vendors' ?
                setAgreementVendorInputTags(input)
                :
                setAgreementPayerInputTags(input)
        } else {
            let type = field === 'payers' ? 'payer_input' : 'vendor_input'
            receiptData[index][type] = input;
            setReceiptData([...receiptData]);
        }
    };

    const handleDeleteTagsAgreement = (tag, field) => {
        field === 'vendors' ?
            agreementVendorTags.splice(tag, 1)
            :
            agreementPayerTags.splice(tag, 1)
    }

    const handleAdditionTagsAgreement = (tag, field) => {
        if ((field === 'vendors' && agreementVendorInputTags !== '')
            || (field === 'payers' && agreementPayerInputTags !== '')) {
            let existingTag = suggestionsTags.find((sT => sT.id === tag.id))
            if (existingTag && existingTag.idUser > 0) {
                insertUserIdToTagAgreement(tag, autocompleteUsers.find(sT => sT.label === tag.text).value, field)
            } else {
                createNewUser(tag, undefined, field, true)
            }
        }
    }

    const insertUserIdToTagAgreement = (tag, userId, field) => {
        tag['idUser'] = userId

        if (field === 'vendors') {
            agreementVendorTags.push(tag)
            setAgreementVendorTags([...agreementVendorTags])
        } else {
            agreementPayerTags.push(tag)
            setAgreementPayerTags([...agreementPayerTags])
        }
    }

    const handleDeleteTags = (tag, index, field) => {
        if (receiptData[index][field].length > 1) {
            receiptData[index][field].splice(tag, 1)
            setReceiptData([...receiptData]);
            updateReceiptData(index, field === 'vendors' ? 'vendor_user_ids' : 'payer_user_ids', true, receiptData[index][field])
        } else {
            NotificationManager.error('', `El campo ${field === 'vendors' ? 'Vendedor' : 'Comprador'} no puede estar vacío`, 3000);
        }
    };

    const handleAdditionTags = (tag, index, field) => {
        let type = field === 'payers' ? 'payer_input' : 'vendor_input'
        if (receiptData[index][type] !== '') {
            let existingTag = suggestionsTags.find((sT => sT.id === tag.id))
            if (existingTag) {
                insertUserIdToTag(tag, autocompleteUsers.find(sT => sT.label === tag.text).value, index, field)
            } else {
                createNewUser(tag, index, field, false)
            }
        }
    };

    const createNewUser = (tag, index, field, isAgreement) => {
        let body = { user: { name: tag.text } }
        axios.post(`${envs.backendUrl}/users`, body).then(res => {
            isAgreement ? insertUserIdToTagAgreement(tag, res.data?.id, field) : insertUserIdToTag(tag, res.data?.id, index, field)
        }).catch(function (e) {
            console.log(e)
        })
    }

    const insertUserIdToTag = (tag, userId, index, field) => {
        tag['idUser'] = userId
        let currentRD = receiptData[index][field]
        if (currentRD.length === 1 && (currentRD[0].name === 'Desconocido' || currentRD[0].name === '')) {
            receiptData[index][field] = [tag]
        } else {
            receiptData[index][field].push(tag)
        }
        setReceiptData([...receiptData]);
        updateReceiptData(index, field === 'vendors' ? 'vendor_user_ids' : 'payer_user_ids', true, receiptData[index][field])
    }

    const tagCompMultipleUser = (data, field, index, isAgreement) => {
        return (
            <ReactTags
                classNames={{
                    tagInputField: 'tagInputClassKey',
                }}
                tags={data}
                suggestions={suggestionsTags}
                placeholder={`Presiona enter para agregar ${field === 'payers' ? 'comprador' : 'vendedor'}`}
                allowDragDrop={false}
                delimiters={delimiters}
                handleAddition={(tag) => { isAgreement ? handleAdditionTagsAgreement(tag, field) : handleAdditionTags(tag, index, field) }}
                handleDelete={(tag) => { isAgreement ? handleDeleteTagsAgreement(tag, field) : handleDeleteTags(tag, index, field) }}
                inputFieldPosition='top'
                autocomplete
                autofocus={false}
                minQueryLength={1}
                allowDeleteFromEmptyInput={false}
                handleInputChange={(k) => handleChangeInputTags(k, index, field, isAgreement)}
            />);
    }

    const createNewAgreement = () => {
        return (
            <div>
                <h6>Crear expediente nuevo</h6>
                <Table borderless className='associatePaymentsTable'>
                    <tbody>
                        <tr>
                            <td>
                                <label>Expediente:</label>
                            </td>
                            <td>
                                <input value={agreementFCCInput} onChange={(e) => { setAgreementFCCInput(e.target.value); setShowCreateAgreementError(false) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>No. Expediente:</label>
                            </td>
                            <td>
                                <input value={agreementNoInput} onChange={(e) => { setAgreementNoInput(e.target.value); setShowCreateAgreementError(false) }} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Comprador:</label>
                            </td>
                            <td className='tagAgreement'>
                                {tagCompMultipleUser(agreementPayerTags, 'payers', undefined, true)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Vendedor:</label>
                            </td>
                            <td className='tagAgreement'>
                                {tagCompMultipleUser(agreementVendorTags, 'vendors', undefined, true)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Condominio:</label>
                            </td>
                            <td>
                                <input value={agreementCondInput} onChange={(e) => setAgreementCondInput(e.target.value)} />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Unidad:</label>
                            </td>
                            <td>
                                <input value={agreementUniInput} onChange={(e) => setAgreementUniInput(e.target.value)} />
                            </td>
                        </tr>
                    </tbody>
                </Table>

                {showCreateAgreementError &&
                    <label className='tdRed'>
                        El número de expediente {agreementNoInput} ya existe para este cliente
                    </label>
                }
            </div>
        )
    }

    const onChangePage = (e, v) => {
        if (paginationCount !== v) {
            setPaginationCount(v)
            setIsLoading(true)
        }
    }

    const context = {
        data: receiptData,
        setData: setReceiptData,
        fetchData: getReceipts,
        sortingColumn,
        setSortingColumn,
        sortingDirection,
        setSortingDirection,
        updateField
    }

    return (
        <>
            <Header />
            <Container className='containerResults'>
                {isLoading ?
                    <Spinner className='spinnerIcon' />
                    :
                    <>
                      {console.log(paymentTotals.payment_type.cheque)}
                        {receiptData.length > 0 ?
                            <>

                                <div id='expInfoContainer' className='expInfoContainer'>
                                    <div className='pt-5'>
                                        <h5 className='expTitle'>
                                            EXPEDIENTE: {receiptData[0].agreement_name ? `${receiptData[0].agreement_name} (${receiptData[0].agreement_sequence})` : 'N/A'}
                                        </h5>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className='ps-2 pe-5'>Divisa</th>
                                                    <th className='px-3'>Depositos en Cheques</th>
                                                    <th className='px-3'>Depositos en Transferencias</th>
                                                    <th className='px-3'>Depositos en Efectivo</th>
                                                    <th className='px-3'>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className='text-start'>Dolares</td>
                                                    <td>{paymentTotals.payment_type.cheque.usd}</td>
                                                    <td>{paymentTotals.payment_type.transferencia.usd}</td>
                                                    <td>{paymentTotals.payment_type.efectivo.usd}</td>
                                                    <td>{paymentTotals.totals_by_currency.usd}</td>
                                                </tr>
                                                <tr>
                                                    <td className='text-start'>Pesos</td>
                                                    <td>{paymentTotals.payment_type.cheque.mxn}</td>
                                                    <td>{paymentTotals.payment_type.transferencia.mxn}</td>
                                                    <td className={`${totalMXNCashExceed && 'tdRed'}`}> {totalMXNCashExceed && <ImWarning />}
                                                        {paymentTotals.payment_type.efectivo.mxn}</td>
                                                    <td>{paymentTotals.totals_by_currency.mxn}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                    <div className='d-flex w-100 py-2 text-left'>
                                        {pagination.count === 1 && <span>1 resultado encontrado</span>}
                                        {pagination.count > 1 && <span>Mostrando {pagination.from} a {pagination.to} de {pagination.count} resultados encontrados.</span>}
                                    </div>
                                    <div className='numbSelected'>
                                        {agreementCheckboxArr?.length > 0 &&
                                            `${agreementCheckboxArr?.length} seleccionado(s)`}
                                    </div>
                                    <div className='d-flex py-3 w-100'>
                                        <Button className='mx-1' color='primary' onClick={() => openImportDataModal(false)}>Importar +</Button>
                                        <Button className='mx-1' color='primary' onClick={() => openImportDataModal(true)}>Expediente Nuevo</Button>
                                        <Button className='mx-1' disabled={!enableAgreementModalButton} onClick={() => openModalDelete()}>Eliminar</Button>
                                        <Button className='ms-auto' onClick={() => openExportDataModal()}>Exportar</Button>
                                    </div>
                                </div>

                                <TableContext.Provider value={context}>
                                    <div id='tableResultsContainer' className='d-flex w-100 tableResultsContainer'>
                                        <Table bordered striped className='fixed-header small'>
                                            <thead>
                                                <tr>
                                                    <th><input value={allCheckboxesSelected} onChange={(e) => changeAllCheckboxAgreement(e.target.checked)} type={'checkbox'} /></th>
                                                    <th>Comprador</th>
                                                    <th>Vendedor</th>
                                                    <th>Monto {<SortButton column="amount" />}</th>
                                                    <th>Fecha de pago{<SortButton column="payment_day" />}</th>
                                                    <th>Doc {<SortButton column="payment_type" />}</th>
                                                    <th>Cuenta Origen {<SortButton column="origin_account" />}</th>
                                                    <th>Cuenta Destino {<SortButton column="destiny_account" />}</th>
                                                    <th>Divisa {<SortButton column="currency" />}</th>
                                                    <th>Tipo de Cambio</th>
                                                    <th>Conversion a MXN</th>
                                                    <th>Conversion a USD</th>
                                                    <th>Comprobante</th>
                                                    <th>Status {<SortButton column="alerts" />}</th>
                                                    <th>Notas</th>
                                                    {currentUser.user_role == 'admin' && <th>Usuario</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {receiptData?.map((reData, index) => (
                                                    <tr key={`trReceipt${reData?.id}`} id={`trReceipt${reData?.id}`}>
                                                        <td><input id={`chkbx${reData?.id}`} name='chkbx' value={reData?.agreementBool} onChange={() => changeCheckboxAgreement(index)} type={'checkbox'} /></td>
                                                        <td>
                                                            {tagCompMultipleUser(reData?.payers, 'payers', index, false)}
                                                        </td>
                                                        <td>
                                                            {tagCompMultipleUser(reData?.vendors, 'vendors', index, false)}
                                                        </td>
                                                        <td>
                                                            {(reData?.payment_type === 'estado_de_cuenta') ?

                                                                <input type={'text'} value={formatCurrency(0) ?? ''} readOnly disabled />
                                                                :
                                                                <AmountField
                                                                    paymentAmount={reData.formatted_amount}
                                                                    index={index}
                                                                />
                                                            }
                                                        </td>
                                                        <td>
                                                            <PaymentDatePicker
                                                                index={index}
                                                                paymentDay={reData.payment_day ? reData.payment_day : null}
                                                            />
                                                            {/* <input type={'date'} lang='es' value={reData?.payment_day ?
                                                        `${moment(reData?.payment_day).format('yyyy-MM-DD')}`
                                                        : undefined}
                                                        onChange={(e) => updateCell(e, index, 'payment_day')} onKeyUp={(e) => blurOnEnter(e)}
                                                        onBlur={(e) => updateReceiptData(index, 'payment_day')} /> */}
                                                        </td>
                                                        <td>
                                                            <select value={reData?.payment_type ?? '-'} onChange={(e) => updateCell(e, index, 'payment_type')} onKeyUp={(e) => blurOnEnter(e)}>
                                                                <option value={'cheque'}>Cheque</option>
                                                                <option value={'efectivo'}>Efectivo</option>
                                                                <option value={'transferencia'}>Transferencia</option>
                                                                <option value={'estado_de_cuenta'}>Estado De Cuenta</option>
                                                                <option value={'contrato'}>Contrato</option>
                                                                <option value={'confirmacion_de_pago'}>Confirmación de Pago</option>
                                                                <option>-</option>
                                                            </select>
                                                        </td>
                                                        <td><input value={(reData?.origin_account === null) ? 'Desconocido' : reData?.origin_account} onChange={(e) => updateCell(e, index, 'origin_account')} onKeyUp={(e) => blurOnEnter(e)}
                                                            onBlur={(e) => updateReceiptData(index, 'origin_account')} />
                                                        </td>
                                                        <td><input value={(reData?.destiny_account === null) ? 'Desconocido' : reData?.destiny_account} onChange={(e) => updateCell(e, index, 'destiny_account')} onKeyUp={(e) => blurOnEnter(e)}
                                                            onBlur={(e) => updateReceiptData(index, 'destiny_account')} />
                                                        </td>
                                                        <td>
                                                            <select className='selectCurrency' value={(reData?.currency === 'desconocido' || reData?.currency === null) ? '-' : reData?.currency} onChange={(e) => updateCell(e, index, 'currency')} onKeyUp={(e) => blurOnEnter(e)}>
                                                                <option value={'mxn'}>MXN</option>
                                                                <option value={'usd'}>USD</option>
                                                                <option>-</option>
                                                            </select>
                                                        </td>
                                                        <td>{(reData?.formatted_exchange_rate === null || reData?.formatted_exchange_rate === '') ? 'Desconocido' : reData?.formatted_exchange_rate}</td>
                                                        <td>{(reData?.formatted_conversion_to_mxn === null || reData?.formatted_conversion_to_mxn === '') ? 'Desconocido' : reData?.formatted_conversion_to_mxn}</td>
                                                        <td>{(reData?.formatted_conversion_to_usd === null || reData?.formatted_conversion_to_usd === '') ? 'Desconocido' : reData?.formatted_conversion_to_usd}</td>

                                                        <td>
                                                            <CustomWidthTooltip arrow placement="bottom" title={<img className='imageTooltip' src={reData?.download_path} />}>
                                                                <div><a target="_blank" rel="noopener noreferrer" href={`/voucher?imgUrl=${reData?.download_path}`}>{reData?.filename}</a></div>
                                                            </CustomWidthTooltip>
                                                        </td>
                                                        <td>
                                                            {reData?.alerts?.length > 0 ?
                                                                <Tooltip followCursor arrow title={<div>{reData?.alerts.map((alert, index) => <div key={index}><label>{`${alert}`}</label><br /></div>)}</div>}>
                                                                    <div>
                                                                        <img src={warningIcon} style={{ width: '20px', marginBottom: '4px' }} />
                                                                        {reData?.alerts.length} Alertas encontradas
                                                                    </div>
                                                                </Tooltip>
                                                                :
                                                                'No hay alertas encontradas'
                                                            }
                                                        </td>
                                                        <td><input value={(reData?.notes === null || reData?.notes === '') ? '' : reData?.notes} onChange={(e) => updateCell(e, index, 'notes')} onKeyUp={(e) => blurOnEnter(e)}
                                                            onBlur={(e) => updateReceiptData(index, 'notes')} /></td>
                                                        {currentUser.user_role == 'admin' && <td>{reData?.internal_user_email}</td>}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </TableContext.Provider>


                                <div className='w-100 py-2 text-center'>
                                    {pagination.count === 1 && <span>1 resultado encontrado</span>}
                                    {pagination.count > 1 && <span>Mostrando {pagination.from} a {pagination.to} de {pagination.count} resultados encontrados.</span>}
                                </div>
                                <Pagination className="paginationSearch" variant="outlined" shape="rounded" color="primary"
                                    count={paginationTotal} page={paginationCount} onChange={(e, v) => onChangePage(e, v)} />
                            </>
                            :
                            <h4 className='emptySearch'>No se encontraron resultados</h4>}
                    </>
                }

                <Modal isOpen={modalDelete} toggle={() => setModalDelete(!modalDelete)} className='modalSearch'>
                    <ModalHeader toggle={() => setModalDelete(!modalDelete)}>Borrar Recibos</ModalHeader>
                    <ModalBody>
                        <Container>
                            <h4>Estás seguro que deseas eliminar {agreementCheckboxArr.length} recibo(s)? </h4>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => deleteSelectedPayments()}>Eliminar</Button>
                        <Button onClick={() => setModalDelete(!modalDelete)}>Cancelar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalExport} toggle={() => setModalExport(!modalExport)}>
                    <ModalHeader toggle={() => setModalExport(!modalExport)}>Exportar</ModalHeader>
                    <ModalBody>
                        <Container className=''>
                            <div className="exportOrder">
                                <strong>Ordenar fechas de manera:</strong>
                                <p>
                                    <label>
                                        <input type="radio" name="exportOrder" value="asc" onClick={() => setExportOrder('asc')} />
                                        Ascendente (De más antiguo a mas reciente)
                                    </label>
                                    <label>
                                        <input type="radio" name="exportOrder" value="desc" onClick={() => setExportOrder('desc')} />
                                        Descendente (De más reciente a mas antiguo)
                                    </label>
                                </p>
                            </div>
                            <div className="addof" onClick={() => setAddDOF(!addDOF)}>

                                <input
                                    type={"checkbox"}
                                    checked={addDOF}
                                    onChange={() => setAddDOF(!addDOF)}
                                />
                                <label>
                                    Agregar tipo de cambio de DOF
                                </label>
                            </div>
                        </Container>
                    </ModalBody>
                    <ModalFooter className='exportButtonFooter'>
                        <Button onClick={() => exportData()}>Exportar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={modalImport} toggle={() => handleCloseImportModal()}>
                    <ModalHeader toggle={() => handleCloseImportModal()}>Importar</ModalHeader>
                    <ModalBody>
                        <Container className=''>
                            {modalNewAgreement && createNewAgreement()}
                            <h6 className='h6TitleSearch'>
                                Arrastre los documentos al área para que sean procesados,
                                las extensiones válidas son jpg, jpeg, png y pdf
                            </h6>
                            <div className='fileUploaderDivSearch'>
                                <FileUploader handleChange={handleChange} name="file"
                                    types={fileTypes} multiple={true} onTypeError={() => fileTypeError()} />
                                <div className='checkboxContainer'>
                                    <div onClick={() => setIsCheck(!isCheck)}>
                                        <input type={'checkbox'} checked={isCheck} onChange={() => setIsCheck(!isCheck)} />
                                        <label>Cheques</label>
                                    </div>
                                    <div onClick={() => setIsCheck(!isCheck)}>
                                        <input type={'checkbox'} checked={!isCheck} onChange={() => setIsCheck(!isCheck)} />
                                        <label>Recibos</label>
                                    </div>
                                </div>
                                <div className={'notesImport'}>
                                    <input
                                        placeholder="Notas"
                                        onChange={(e) => setNotes(e.target.value)}
                                        value={notes}
                                    />
                                </div>
                            </div>
                            {files && files.length > 0 &&
                                <div className='selectedFilesDiv'>
                                    <ul className='ulFiles'>
                                        {files.map((f, index) => (
                                            <li key={index} className='liFiles'>
                                                <label>{f.name}</label>
                                                <Button color='danger' onClick={() => removeFileFromSelected(index)}>Remove</Button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </Container>
                    </ModalBody>
                    <ModalFooter className='exportButtonFooter'>
                        <Button color='primary' onClick={() => importData()}>Importar</Button>
                        <Button onClick={() => handleCloseImportModal()}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </Container>
            <NotificationContainer />
        </>
    );
}

export default SearchUserData;
