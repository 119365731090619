export function defaultDataSort(data, col, direction) {
  return data.sort(function (a, b) {
    let aSort =
        typeof a[col] === "string" ? a[col].toUpperCase() : a[col];
    let bSort =
        typeof b[col] === "string" ? b[col].toUpperCase() : b[col];

    aSort = a[col] === "" ? null : aSort;
    bSort = b[col] === "" ? null : bSort;

    let comparison = 0;
    if (aSort === null) {
        comparison = 1;
    } else if (bSort === null) {
        comparison = -1;
    } else if (aSort > bSort) {
        comparison = 1;
    } else if (aSort < bSort) {
        comparison = -1;
    }
    return !direction ? comparison * -1 : comparison;
  });
}

export function dateSort(data, col, direction) {
  data.sort(function (a, b) {
    return !direction ? new Date(b[col]) - new Date(a[col]) : new Date(a[col]) - new Date(b[col])
  });
}
