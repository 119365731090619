import './App.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { NotificationContainer } from 'react-notifications';

import LoginPage from './content/LoginPage';
import MainPage from './content/MainPage';
import ProcessedData from './content/ProcessedData';
import SearchUserData from './content/SearchUserData';
import AgreementsPage from './content/AgreementsPage';
import { LogsPage } from './content/LogsPage';
import UsersPage from './content/users/UsersPage';
import VoucherImage from './content/VoucherImage';
import CreateUserPage from './content/CreateUserPage';
import ChangePasswordPage from './content/ChangePasswordPage';

function App() {
  axios.defaults.headers.common['Authorization'] = localStorage.token;

  return (
    <div className="App">
      <RecoilRoot>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="agreements" element={<AgreementsPage />} />
          <Route path="results" element={<ProcessedData />} />
          <Route path="search/:logId" element={<SearchUserData logSearch />} />
          <Route path="search" element={<SearchUserData />} />
          <Route path="logs" element={<LogsPage />} />
          <Route path='users' element={<UsersPage />} />
          <Route path='voucher' element={<VoucherImage />} />
          <Route path='createUser' element={<CreateUserPage />} />
          <Route path='changePassword' element={<ChangePasswordPage />} />
        </Routes>
      </RecoilRoot>
      <NotificationContainer />
    </div>
  );
}

export default App;
