
import { useState } from 'react';
import { Button, Container, Table } from 'reactstrap';
import './styles/ChangePasswordPage.css';
import envs from "../config";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import axios from 'axios';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function ChangePasswordPage() {

    const [errorCall, setErrorCall] = useState(false)
    const [tempPassword, setTempPassword] = useState(localStorage.getItem('temp_password'))
    const [blindTempPassword, setBlindTempPassword] = useState(true)
    const [newPassword, setNewPassword] = useState('')
    const [blindNewPassword, setBlindNewPassword] = useState(true)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [blindConfirmPassword, setBlindConfirmPassword] = useState(true)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const saveOnEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            changePassword()
        }
    }

    const changePassword = () => {
        var regExPassword = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        if (tempPassword.trim() === '' || newPassword.trim() === '' || confirmPassword.trim() === '') {
            NotificationManager.error('', 'Los campos no pueden estar vacíos', 2000);
            return;
        } else if (newPassword.trim() !== confirmPassword.trim()) {
            NotificationManager.error('', 'La contraseña no coincide con la confirmación', 2000);
            return;
        } else if (!regExPassword.test(newPassword)) {
            NotificationManager.error('', 'La contraseña debe tener al menos 8 caracteres, una mayúscula, un caracter especial y un número', 5000);
            return;
        }

        axios.put(`${envs.backendUrl}/internal_users/${urlParams.get('internalUserId')}`, {
            user: {
                old_password: tempPassword,
                new_password: newPassword,
                conf_password: confirmPassword
            }
        }).then(res => {
            if (res.status === 200 || res.status === 201) {
                localStorage.removeItem('temp_password')
                NotificationManager.success('', 'Contraseña modificada', 2000);
                setTimeout(() => {
                    window.location = '/'
                }, 2000);
            } else {
                NotificationManager.error('', 'Error al querer cambiar la contraseña, inténtalo más tarde', 5000);
            }
        }).catch(function (e) {
            console.log(e)
            NotificationManager.error('', e.response.data.message, 5000);
        })
    }

    return (
        <>
            <Container className='changePasswordPageContainer'>
                <label><strong>Usuario:</strong> {urlParams.get('email')}</label>
                <Table borderless>
                    <tbody>
                        <tr>
                            <th>Contraseña temporal:</th>
                            <th><input value={tempPassword} onChange={(e) => { setTempPassword(e.target.value); errorCall && setErrorCall(false) }} type={blindTempPassword ? "password" : "text"} /></th>
                            <th><Button className='blindBtn' onClick={() => setBlindTempPassword(!blindTempPassword)}>
                                {blindTempPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                            </Button></th>
                        </tr>
                        <tr>
                            <th>Nueva contraseña:</th>
                            <th>
                                <input onKeyUp={(e) => saveOnEnter(e)} onChange={(e) => { setNewPassword(e.target.value); }} type={blindNewPassword ? "password" : "text"} />
                            </th>
                            <th><Button className='blindBtn' onClick={() => setBlindNewPassword(!blindNewPassword)}>
                                {blindNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                            </Button></th>
                        </tr>
                        <tr>
                            <th>Confirmar contraseña:</th>
                            <th>
                                <input onKeyUp={(e) => saveOnEnter(e)} onChange={(e) => { setConfirmPassword(e.target.value); }} type={blindConfirmPassword ? "password" : "text"} />
                            </th>
                            <th><Button className='blindBtn' onClick={() => setBlindConfirmPassword(!blindConfirmPassword)}>
                                {blindConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                            </Button></th>
                        </tr>
                    </tbody>
                </Table>
                <Button onClick={() => changePassword()}>Guardar</Button>
                <NotificationContainer />
            </Container>
        </>
    );
}

export default ChangePasswordPage;
