const envs = {
  // LOCAL
  // backendUrl: "http://localhost:3000",
  // cableUrl: 'ws://localhost:3000/cable',


  // STAGING
  // backendUrl: "https://notaria-ocr-stg.herokuapp.com",
  // cableUrl: "wss://notaria-ocr-stg.herokuapp.com/cable",

  // PROD (dev branch)
  backendUrl: "https://notaria-ocr-stage.herokuapp.com",
  cableUrl: "wss://notaria-ocr-stage.herokuapp.com/cable",
}

export default envs
