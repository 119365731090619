import { atom } from 'recoil';

const notificationsState = atom({
  key: 'notificationsState',
  default: []
});
const notificationsCountState = atom({
  key: 'notificationsCountState',
  default: 0
});

export {
  notificationsState,
  notificationsCountState
}
