import React, { useEffect, useState } from 'react';
import logo from '../content/assets/logo.svg';
import { Button, Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import { RiUserShared2Line } from "react-icons/ri";
import { NotificationsBell } from './NotificationsBell';
import { SettingsList } from './SettingsList';
import { useRecoilValue } from 'recoil';
import { currentUserState } from '../atoms/users';


export const Header = () => {
  const currentUser = useRecoilValue(currentUserState);
  const [createUserPermission, setCreateUserPermission] = useState(false);

  useEffect(() => {
    if (currentUser.user_role === 'admin' && currentUser.user_permissions === 'new_user_creation')
      setCreateUserPermission(true)
  }, [])

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    window.location = '/';
  }

  return (
    <header className='headerContainer'>
      <Navbar color='dark' dark>
        <div className='navBarContainer'>
          <div>
            <Link className='agreementsLink' to='/agreements'>Expedientes</Link>
          </div>
          <div className='importLinkContainer'>
            <Link className='importLink' to='/logs'>Importaciones</Link>
          </div>
          <div className='importLinkContainer'>
            <Link className='importLink' to='/users'>Clientes</Link>
          </div>

          <div className='logoutContainer' style={!createUserPermission ? { marginLeft: '92%' } : {}}>
            {createUserPermission && <SettingsList />}
            <NotificationsBell />
            <Button className='logoutButton' onClick={() => logout()}><RiUserShared2Line /></Button>
          </div>
        </div>
      </Navbar>
    </header>
  )
}
