import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Button,
  Container,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  NotificationManager,
} from "react-notifications";
import { Header } from "../../components/Header";
import envs from '../../config';
import { TableContext } from '../../context';
import { InputEditableCell } from '../../components/InputEditableCell';
import { SortButton } from '../../components/SortButton';
import { highlightChangedRow } from '../../lib/highlightChangedRow';
import useSelectedRows from '../../hooks/useSelectedRows';
import { dateSort, defaultDataSort } from '../../lib/dataSort';
import useDebounce from '../../hooks/useDebounce';

function UsersPage() {
  const [users, setUsers] = useState([]);
  const [valueIsChanging, setValueIsChanging] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [deleteOption, setDeleteOption] = useState('destroy_scope_user_only');
  const { allCheckboxesSelected, checkboxesSelected, CheckboxInput, deselectAll, enableActions, selectAll } = useSelectedRows({ data: users, setData: setUsers });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    fetchUsers();
    document.title = 'Clientes'
  }, []);

  useEffect(
    () => {
      setIsSearching(true);
      fetchUsers(debouncedSearchTerm).then((results) => {
        setIsSearching(false);
      });
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  const updateCell = (event, index, field) => {
    users[index][field] = event.target.value;
    setUsers([...users]);
    setValueIsChanging(true);
  };

  const updateData = (index, field) => {
    if (valueIsChanging) {
      let bodyVal = {};
      bodyVal[field] = users[index][field];
      axios.put(`${envs.backendUrl}/users/${users[index].id}`,
        { user: bodyVal }).then(() => {
          highlightChangedRow(index);
          NotificationManager.success('', 'Cambio Guardado', 2000);
          setValueIsChanging(false);
        }).catch(function (e) {
          console.log(e);
          NotificationManager.error(
            '',
            'Algo anda mal, prueba más tarde',
            2000
          )
        });
    }
  };

  const sortData = (col, direction) => {
    switch (col) {
      case 'created_at':
        dateSort(users, col, direction);
        break;

      default:
        defaultDataSort(users, col, direction);
        break;
    }
    setUsers([...users]);
  };

  const fetchUsers = (name = null) => {
    let url = `${envs.backendUrl}/users`;
    if (name) url = `${url}?search=${name}`
    return axios.get(url)
      .then((response) => response.data)
      .then((data) => setUsers(data));
  }

  const deleteUsers = () => {
    axios.delete(`${envs.backendUrl}/users/remove_multiple`, {
      data: {
        ids: checkboxesSelected,
        scope: deleteOption
      },
    }).then((res) => {
      const bar = document.getElementById('searchbar');
      fetchUsers(bar.value);
      setModalDeleteOpen(false);
      deselectAll();
      NotificationManager.success("", "Usuario(s) eliminados", 2000);
    })
      .catch(function (e) {
        console.log(e);
        NotificationManager.error("", "Algo anda mal, prueba más tarde", 2000);
      });
  }

  const context = {
    updateCell,
    updateData,
    sortData,
    data: users,
    setData: setUsers
  };

  return (
    <TableContext.Provider value={context}>
      <Header />
      <Container>
        <h4 className="h4Imp">Personas / Empresas</h4>
        <input
          placeholder="Buscar personas / empresas por nombre"
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: "90%", padding: "6px", margin: "0 auto 40px" }}
          id="searchbar"
        />
        <div className="deleteAgreementsContainer">
          <Button
            disabled={!enableActions}
            onClick={() => setModalDeleteOpen(true)}
          >
            Eliminar
          </Button>
        </div>
        <Table bordered striped className="tableUsers">
          <thead>
            <tr>
              <th>
                <input
                  value={allCheckboxesSelected}
                  onChange={(e) => selectAll(e.target.checked)}
                  type={"checkbox"}
                />
              </th>
              <th>Nombre <SortButton column="name" /></th>
              <th>Expedientes <SortButton column="agreements_count" /></th>
              <th>Pagos <SortButton column="payments_count" /></th>
              <th>Fecha Creación <SortButton column="created_at" /></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index} id={`${index}TrId`}>
                <td>
                  <CheckboxInput val={user?.chkbxBool || false} index={index} />
                </td>
                <td>
                  <InputEditableCell
                    val={user?.payer_name === null
                      ? "Desconocido"
                      : user?.name}
                    index={index}
                    field={"name"}
                  />
                </td>
                <td><Link to={`/search?personId=${user.id}`}>{user.agreements_count}</Link></td>
                <td><Link to={`/search?personId=${user.id}`}>{user.payments_count}</Link></td>
                <td>{moment(user.created_at).format('DD/MM/yyyy')}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal
          isOpen={modalDeleteOpen}
          toggle={() => setModalDeleteOpen(!modalDeleteOpen)}
          className="modalDeleteExpAndPaym"
        >
          <ModalHeader toggle={() => setModalDeleteOpen(!modalDeleteOpen)}>
            Confirmación de eliminación de Usuario(s)
          </ModalHeader>
          <ModalBody>
            <Container className="modalDeleteContainer">
              <h6>Selecciona la acción que deseas ejecutar: </h6>
              <div>
                <input
                  type="radio"
                  value="destroy_scope_user_only"
                  name="deleteOption"
                  onChange={(e) => setDeleteOption(e.target.value)}
                  checked={deleteOption === 'destroy_scope_user_only'}
                />
                <label onClick={(e) => setDeleteOption('destroy_scope_user_only')}>
                  Eliminar solo persona/empresa
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="deleteOption"
                  value="destroy_scope_with_payments"
                  onChange={(e) => setDeleteOption(e.target.value)}
                  checked={deleteOption === 'destroy_scope_with_payments'}
                />
                <label onClick={(e) => setDeleteOption('destroy_scope_with_payments')}>
                  Eliminar persona/empresa y pagos asociados
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="deleteOption"
                  value="destroy_scope_all"
                  onChange={(e) => setDeleteOption(e.target.value)}
                  checked={deleteOption === 'destroy_scope_all'}
                />
                <label onClick={(e) => setDeleteOption('destroy_scope_all')}>
                  Eliminar persona/empresa, pagos y expedientes asociados
                </label>
              </div>
            </Container>
          </ModalBody>
          <ModalFooter className="footerDeleteExpAndPaym">
            <Button color="danger" onClick={() => deleteUsers()}>
              Eliminar
            </Button>
            <Button onClick={() => setModalDeleteOpen(!modalDeleteOpen)}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </TableContext.Provider>
  )
}

export default UsersPage;
