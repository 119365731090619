import { useEffect, useState } from 'react';

function useSelectedRows ({ data, setData }) {
  const [allCheckboxesSelected, setAllCheckboxesSelected] = useState(false);
  const [enableActions, setEnableActions] = useState(false);
  const [checkboxesSelected, setCheckboxesSelected] = useState([]);

  const changeCheckbox = (index) => {
    data[index].chkbxBool = !data[index].chkbxBool;
    setEnableActions(data.find((row) => row.chkbxBool === true));
    setData([...data]);
    checkSelectedCheckboxes();
  };

  const selectAll = (selected) => {
    data.forEach((row) => {
        row.chkbxBool = selected;
    });

    let chkbx = document.getElementsByName("chkbxInput");
    chkbx.forEach((cb) => {
        cb.checked = selected;
    });

    setEnableActions(selected);
    setData([...data]);
    setAllCheckboxesSelected(selected);
  };

  const deselectAll = () => {
    data.forEach((aD) => {
        aD.chkbxBool = false;
    });

    let chkbx = document.getElementsByName("chkbxInput");
    chkbx.forEach((cb) => {
        cb.checked = false;
    });

    setEnableActions(false);
    setData([...data]);
    setAllCheckboxesSelected(false);
  };

  const CheckboxInput = ({val, index}) => {
    const [checked, setChecked] = useState(val);

    const handleChange = () => {
      setChecked(!checked);
      changeCheckbox(index);
    }

    return (
      <input
        name="chkbxInput"
        checked={checked}
        onChange={() => handleChange()}
        type={"checkbox"}
      />
    )
  };

  const checkSelectedCheckboxes = () => {
    const checkedCheckboxes = [];
    data.forEach((item) => {
      item.chkbxBool && checkedCheckboxes.push(item.id);
    });
    setCheckboxesSelected(checkedCheckboxes);
  };

  return {
    allCheckboxesSelected,
    enableActions,
    changeCheckbox,
    selectAll,
    deselectAll,
    CheckboxInput,
    checkboxesSelected
  }
}

export default useSelectedRows;
