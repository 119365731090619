import { useContext } from "react";
import { TableContext } from "../context";
export const InputEditableCell = ({val, index, field}) => {

  const { updateCell, updateData } = useContext(TableContext);

  const blurOnEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  };

  return (
      <input
          value={val}
          onChange={(e) => updateCell(e, index, field)}
          onKeyUp={(e) => blurOnEnter(e)}
          onBlur={() => updateData(index, field)}
      />
  );
};
