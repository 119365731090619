
import axios from 'axios';
import { useState } from 'react';
import { Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { Header } from '../components/Header';
import './styles/CreateUserPage.css';
import envs from "../config";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";

function CreateUserPage() {

    const [showLink, setShowLink] = useState(false)
    const [email, setEmail] = useState('')
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [internalUserPassword, setInternalUserPassword] = useState('')
    const [internalUserId, setInternalUserId] = useState('')

    const validateEmail = () => {
        let regExEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
        regExEmail.test(email) ?
            sendInvitation()
            :
            NotificationManager.error('', 'Escribe un email válido', 2000);
    }

    const sendInvitation = () => {
        axios.post(`${envs.backendUrl}/internal_users`, {
            user: {
                email: email,
                role: 'regular',
                permissions: []
            }
        }).then(res => {
            if (res.status === 200 || res.status === 201) {
                NotificationManager.success('', 'Invitación enviada', 2000);
                let user = res.data?.user;
                setShowLink(true)
                setInternalUserPassword(user?.password)
                setInternalUserId(user?.id)
                localStorage.setItem('temp_password', user?.password);
            } else {
                NotificationManager.error(res?.data?.details, res?.data?.message, 5000);
            }
        }).catch(function (e) {
            console.log(e)
            NotificationManager.error(e.response?.data?.details, e.response?.data?.message, 5000);
        })
    }

    const changePassword = () => {
        setModalConfirmation(true)
    }

    const navigateChangePassword = () => {
        logout()
    }

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        login()
    }

    const login = () => {
        axios
            .post(`${envs.backendUrl}/sessions`, { email: email, password: internalUserPassword })
            .then((resp) => resp.data)
            .then((data) => {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user.id);
                localStorage.setItem('user_role', data.user.role);
                localStorage.setItem('user_permissions', data.user.permissions);
                return window.location = `/changePassword?internalUserId=${internalUserId}&email=${email}`
            })
            .catch((err) => { console.log(err) });
    }

    return (
        <>
            <Header />
            <Container className='createUserPageContainer'>
                <h4>Se enviará una invitación al correo ingresado para que pueda iniciar sesión con una contraseña autogenerada. Una vez que la acepte, podrá hacer uso de la aplicación</h4>
                <Table borderless>
                    <tbody>
                        <tr>
                            <th>Correo</th>
                            <th><input onChange={(e) => setEmail(e.target.value)} /></th>
                        </tr>
                    </tbody>
                </Table>
                <Button onClick={() => validateEmail()}>Enviar invitación</Button>
                {showLink && <div>
                    <a onClick={() => changePassword()}>Cambiar la contraseña temporal</a>
                </div>}

                <Modal isOpen={modalConfirmation} toggle={() => setModalConfirmation(!modalConfirmation)} className='modalSearch'>
                    <ModalHeader toggle={() => setModalConfirmation(!modalConfirmation)}>Salir de la cuenta</ModalHeader>
                    <ModalBody>
                        <Container>
                            <h4>Estás seguro que deseas navegar al apartado de cambio de contraseña? Se cerrará tu sesión actual </h4>
                        </Container>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='primary' onClick={() => navigateChangePassword()}>Confirmar</Button>
                        <Button onClick={() => setModalConfirmation(!modalConfirmation)}>Cancelar</Button>
                    </ModalFooter>
                </Modal>
            </Container>
            <NotificationContainer />
        </>
    );
}

export default CreateUserPage;
