import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil';
import '../content/styles/header.css';
import moment from 'moment';
import '../content/styles/header.css';
import useOnClickOutside from '../hooks/useOnClickOutside';
import { useNavigate } from 'react-router-dom';

import { notificationsState, notificationsCountState } from '../atoms/notifications';
import { useNotifications } from '../hooks/useNotifications';

const EnabledDot = ({ count }) => (
  <div style={{
    width: '20px',
    height: '20px',
    padding: '4px',
    backgroundColor: 'red',
    borderRadius: '50%',
    position: 'absolute',
    right: '-8px',
    bottom: '24px',
    fontSize: '10px',
  }}>{count}</div>
)

export const NotificationsBell = () => {
  const { fetchNotifications, markAsRead, markAllAsRead } = useNotifications();
  const notifications = useRecoilValue(notificationsState)
  const notificationsCount = useRecoilValue(notificationsCountState)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleIcon = (e) => {
    e.preventDefault();
    toggle();
  }
  useOnClickOutside(ref, () => setDropdownOpen(false));

  useEffect(() => {
    fetchNotifications();
  }, [])

  return (
    <div id="notification-bell" ref={ref}>
      <img onClick={(e) => toggleIcon(e)} src="data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTYgMTYiPjxwYXRoIGQ9Ik0xMyA1QTUgNSAwIDAgMCAzIDV2NWwtMyAydjFoMTZ2LTFsLTMtMlY1ek0xMCAxNEg2YTIgMiAwIDEgMCA0IDB6IiBmaWxsPSIjZmZmZmZmIiBjbGFzcz0iZmlsbC0wMDAwMDAiPjwvcGF0aD48L3N2Zz4=" width="28" height="28" />
      {dropdownOpen && <ul>
        {(() => {
          if (notifications.length > 0) {
            return (
              <>
                <li className={'markAllAsRead'}>
                  <a onClick={() => {
                    markAllAsRead().then(() => {
                      toggle();
                    });
                  }}>Marcar todas como leídas</a>
                </li>
                {notifications.map((notification) => (
                  <li key={notification.id} className={`${!notification.read && 'notificationUnread'}`}>
                    <a onClick={() => {
                      markAsRead(notification.id).then(() => {
                        navigate(`/search?logId=${notification.notifiable_id}`);
                        toggle();
                      });
                    }}>
                      <strong>{notification.content}</strong>
                      <small>
                        {moment(notification.created_at).format('DD-MM-yyyy hh:mm')}
                      </small>
                    </a>
                  </li>
                ))}
              </>
            )
          } else {
            return <li>No hay notificaciones.</li>
          }
        })()}
      </ul>}
      {notificationsCount > 0 &&
        <EnabledDot count={notificationsCount} />}
    </div>
  )
}
