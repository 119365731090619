import React, { useRef, useState } from 'react'
import '../content/styles/header.css';
import { AiFillSetting } from "react-icons/ai";
import { Button } from 'reactstrap';
import useOnClickOutside from '../hooks/useOnClickOutside';

export const SettingsList = () => {

    const ref = useRef();
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const toggleIcon = (e) => {
        e.preventDefault();
        toggle();
    }

    useOnClickOutside(ref, () => setDropdownOpen(false));

    return (
        <div className="settingsList" ref={ref}>
            <Button className='logoutButton' onClick={(e) => toggleIcon(e)}><AiFillSetting /></Button>
            {
                dropdownOpen && <ul>
                    {(() => {
                        return (
                            <>
                                <li>
                                    <strong><a href='/createUser'>Crear Usuario</a></strong>
                                </li>
                            </>
                        )
                    })()}
                </ul>
            }
        </div >
    )
}
