import ReactPanZoom from 'react-image-pan-zoom-rotate';
import './styles/VoucherImage.css';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

function VoucherImage() {

    return (
        <div className='ReactPanZoomContainer'>
            <ReactPanZoom
                image={urlParams.get('imgUrl')}
                alt="Image alt text"
            />
        </div>
    );
}

export default VoucherImage;
