import logo from './assets/logo.svg';
import {Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Navbar, NavbarBrand, Table} from 'reactstrap';
import './styles/ProcessedData.css';
import {useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { BsArrowLeftCircle } from "react-icons/bs";
import axios from 'axios';
import envs from '../config';

function ProcessedData() {

    const navigate = useNavigate()
    const location = useLocation();
    const [data, setData] = useState();
    const [modal, setModal] = useState(false);
    const [selectedData, setSelectedData] = useState();

    useEffect(() => {
        if (localStorage.getItem('token') != null) {
            axios
              .get(`${envs.backendUrl}/login`, {
                headers: {
                  Authorization: localStorage.token,
                },
              })
              .then((resp) => resp.data)
              .then((user) => {
                localStorage.setItem('user_id', user.id);
              });
        } else {
            window.location = '/'
        }
    }, [])

    useEffect(()=> {
        setData(location.state.data)
    },[])

    const openModal = (index) => {
        data?.result[index]?.text.replace(/\n/g, "<br />")
        setData({...data})
        setSelectedData(index)
        setModal(true)
    }

return (
    <>
        <header>
            <Navbar color='dark' dark>
                <NavbarBrand>
                    <Button className='backBtn' onClick={()=> navigate(-1)}><BsArrowLeftCircle/></Button>
                    <label className='labelNotaria'>Notaria</label>
                    <div className='searchLinkContainer'>
                        <a className='searchLink' href='search'>Buscar</a>
                    </div>
                </NavbarBrand>
            </Navbar>
        </header>
        <Container className='containerResults'>
            {data && data?.result?.length > 0 ? <>
                <div className='tableContainer'>
                    <Table bordered striped>
                        <thead>
                            <tr>
                                <th>Nombre Original</th>
                                <th>Precisión</th>
                                <th>Datos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.result?.map((d,index)=> (
                                <tr key={index}>
                                    <td>{d.filename}</td>
                                    <td>{`${parseFloat(d.confidence).toFixed(2)}%`}</td>
                                    <td><Button className='openModalBtn' onClick={()=>openModal(index)}>Ver</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                    <ModalHeader toggle={() => setModal(!modal)}>{data?.result[selectedData]?.filename}</ModalHeader>
                    <ModalBody>
                        {data?.result[selectedData]?.text}
                    </ModalBody>
                    <ModalFooter>
                    <Button color="secondary" onClick={() => setModal(!modal)}>
                        Cerrar
                    </Button>
                    </ModalFooter>
                </Modal>
            </>
            :
            <>
                Hubo un error al procesar los archivos, inténtalo de nuevo
            </>
            }
        </Container>
    </>
  );
}

export default ProcessedData;
