import { atom } from 'recoil';

const data = {
  users: localStorage.getItem('autocompleteUsers') ? JSON.parse(decodeURI(localStorage.getItem('autocompleteUsers'))) : [],
  suggestions: localStorage.getItem('suggestionTags') ? JSON.parse(decodeURI(localStorage.getItem('suggestionTags'))) : []
}

const autocompleteState = atom({
  key: 'autocompleteState',
  default: data
});

export {
  autocompleteState
}
