import { atom } from "recoil";

const usersState = atom({
  key: "usersState",
  default: ({})
});

const defaultCurrentUser = () => {
  if (localStorage.getItem('token')) {
    return {
      token: localStorage.getItem('token'),
      user_id: localStorage.getItem('user_id'),
      user_role: localStorage.getItem('user_role'),
      user_permissions: localStorage.getItem('user_permissions')
    }
  } else {
    return {};
  }
}

const currentUserState = atom({
  key: "currentUserState",
  default: (defaultCurrentUser())
});

export {
  currentUserState,
  usersState
}
