import React, { useEffect, useState } from 'react';
import axios from 'axios';
import envs from '../config';
import moment from 'moment';
import './styles/LogsPage.css';

import {
  TiArrowSortedUp,
  TiArrowSortedDown,
  TiArrowUnsorted,
} from "react-icons/ti";

import { Link } from 'react-router-dom';
import { Container, Button, Table } from 'reactstrap';
import { Header } from '../components/Header';
import { currentUserState } from '../atoms/users';
import { useRecoilValue } from 'recoil';

export const LogsPage = () => {
  const currentUser = useRecoilValue(currentUserState);
  const [logs, setLogs] = useState([]);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortingDirection, setSortingDirection] = useState(true);

  useEffect(() => {
    axios.get(`${envs.backendUrl}/job_logs`)
      .then((resp) => resp.data)
      .then((logs) => {
        setLogs(logs);
      })
    document.title = 'Importaciones'
  }, []);

  const sortColumnFunc = (col) => {
    let sortDirAux = sortingDirection;
    sortDirAux = sortingColumn !== col ? null : sortingDirection;
    if (sortDirAux === null) {
      setSortingDirection(true);
      setSortingColumn(col);
      sortDataFunc(col, true);
    } else if (sortDirAux) {
      setSortingDirection(false);
      setSortingColumn(col);
      sortDataFunc(col, false);
    } else {
      setSortingDirection(null);
      setSortingColumn("");
    }
  };

  const sortDataFunc = (col, direction) => {
    switch (col) {
      case 'created_at':
        logs.sort(function (a, b) {
          return direction ? new Date(b[col]) - new Date(a[col]) : new Date(a[col]) - new Date(b[col])
        });
        break;
      case 'quantity':
        logs.sort(function (a, b) {
          let comparison = 0;
          if (a.quantity === null) {
            comparison = 1;
          } else if (b.quantity === null) {
            comparison = -1;
          } else if ((a.quantity - b.quantity) < 0) {
            comparison = 1;
          } else if ((a.quantity - b.quantity) > 0) {
            comparison = -1;
          }
          return !direction ? comparison * -1 : comparison;
        });
        break;

      default:
        logs.sort(function (a, b) {
          let aSort =
            typeof a[col] === "string" ? a[col].toUpperCase() : a[col];
          let bSort =
            typeof b[col] === "string" ? b[col].toUpperCase() : b[col];

          aSort = a[col] === "" ? null : aSort;
          bSort = b[col] === "" ? null : bSort;

          let comparison = 0;
          if (aSort === null) {
            comparison = 1;
          } else if (bSort === null) {
            comparison = -1;
          } else if (aSort > bSort) {
            comparison = 1;
          } else if (aSort < bSort) {
            comparison = -1;
          }
          return !direction ? comparison * -1 : comparison;
        });
        break;
    }
    setLogs([...logs]);
  };

  const sortButton = (colName) => {
    return (
      <Button className="clearBtn" onClick={() => sortColumnFunc(colName)}>
        {sortingColumn === colName ? (
          sortingDirection ? (
            <TiArrowSortedUp />
          ) : (
            <TiArrowSortedDown />
          )
        ) : (
          <TiArrowUnsorted />
        )}
      </Button>
    );
  };


  return (
    <>
      <Header />
      <Container className="containerResults">
        <>
          <h4 className="h4Imp">Importaciones</h4>
          <Table bordered striped>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Docs{sortButton("quantity")}</th>
                <th>Fecha{sortButton("created_at")}</th>
                <th>Status{sortButton("i18n_status")}</th>
                <th>Expediente{sortButton("agreement_reference")}</th>
                <th>Notas</th>
                {currentUser.user_role == 'admin' && <th>Usuario</th>}
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => (
                <tr key={index}>
                  <td>
                    {log.status == "verified" ? <Link to={`/search?logId=${log.id}`}>{log.name}</Link> : log.name}
                  </td>
                  <td>{log.quantity}</td>
                  <td>{moment(log.created_at).utcOffset(log.created_at).format('DD/MM/yyyy hh:mm A')}</td>
                  <td>{log.i18n_status}</td>
                  <td>{log.agreement_reference}</td>
                  <td>{log.notes}</td>
                  {currentUser.user_role == 'admin' && <td>{log.internal_user_email}</td>}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      </Container>
    </>
  )
}
