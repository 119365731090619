import { useContext } from "react";
import { TableContext } from "../context";

import {
  TiArrowSortedUp,
  TiArrowSortedDown,
  TiArrowUnsorted,
} from "react-icons/ti";
import { Button } from 'reactstrap';

const SortButton = ({ column }) => {
  const {
    sortingColumn,
    setSortingColumn = 'payment_day',
    sortingDirection = 'asc',
    setSortingDirection
  } = useContext(TableContext);

  const sortColumnFunc = (col, newDirection) => {
    if (col && newDirection) {
      setSortingDirection(newDirection);
      setSortingColumn(col);
    } else {
      setSortingColumn(null);
    }
    // sortData(col, true);
  };

  return (
    sortingColumn === null && sortingDirection === null && 'payment_day' === column ?
      <Button className="clearBtn" onClick={() => sortColumnFunc(column, 'desc')}>
        <TiArrowSortedUp />
      </Button>
      :
      sortingColumn === column ? (
        sortingDirection === 'asc' ? (
          <Button className="clearBtn" onClick={() => sortColumnFunc(column, 'desc')}>
            <TiArrowSortedUp />
          </Button>
        ) : (
          <Button className="clearBtn" onClick={() => sortColumnFunc(null, null)}>
            <TiArrowSortedDown />
          </Button>
        )
      ) : (
        <Button className="clearBtn" onClick={() => sortColumnFunc(column, 'asc')}>
          <TiArrowUnsorted />
        </Button>
      )
  );
};

export {
  SortButton
};
