import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { TableContext } from '../context';
import moment from 'moment';

export default function PaymentDatePicker ({ paymentDay, index }) {
  const [date, setDate] = useState();
  const { updateField } = useContext(TableContext);

  useEffect(() => {
    setDate(paymentDay ? moment(paymentDay).toDate() : null)
  }, []);


  const updateData = (newDate) => {
    updateField(newDate, index, 'payment_day');
  }

  useEffect(() => {
    setDate(paymentDay ? moment(paymentDay).toDate() : null)
}, [])

  return (
    <DatePicker
      selected={date}
      dateFormat="dd/MM/yyyy"
      onChange={ (newDate) => {
        setDate(newDate)
        updateData(newDate)
      } }
      placeholderText="Desconocido"
      showYearDropdown
      yearDropdownItemNumber={15}
      scrollableYearDropdown
    />
  );
}
