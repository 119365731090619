import React, { useState, useContext } from 'react';
import { TableContext } from '../context';
import { formatCurrency } from '../lib/formatCurrency';

export default function AmountField({ paymentAmount, index }) {
  const initialAmount = paymentAmount ? paymentAmount : '$0.00';
  const [amount, setAmount] = useState(initialAmount);
  const { updateField } = useContext(TableContext);

  const updateData = () => {
    updateField(amount, index, 'amount').then((r) => {
      setAmount(formatCurrency(r.data.row.amount));
    });
  }

  const blurOnEnter = (e) => {
    if (e.keyCode === 13) {
        e.preventDefault();
        e.target.blur();
    }
  }

  const handleAmountChange = (event) => {
    let newAmount = event.target.value.trim();
    const regex = /^\$?[\d,]+(?:\.\d{1,2})?\.?$/;

    if (newAmount === "" || newAmount === "$") {
      setAmount("$");
    } else if (regex.test(newAmount)) {
      setAmount(newAmount);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={amount} onChange={handleAmountChange}
        onKeyUp={(e) => blurOnEnter(e)}
        onBlur={() => updateData()}
        step="0.01"
      />
    </div>
  );
}


