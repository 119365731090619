import { Button, Container, Table } from 'reactstrap';
import './styles/LoginPage.css';
import { useState } from 'react';
import axios from 'axios';
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import envs from '../config';

function LoginPage() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorCall, setErrorCall] = useState(false)
    const [blindPassword, setBlindPassword] = useState(true)

    const loginFunc = () => {
        if (username.trim() === '') {
            NotificationManager.error('', 'El usuario no puede ser vacío', 2000);
            return;
        } else if (password === '') {
            NotificationManager.error('', 'La contraseña no puede ser vacía', 2000);
            return;
        }
        axios
            .post(`${envs.backendUrl}/sessions`, { email: username, password: password })
            .then((resp) => resp.data)
            .then((data) => {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user.id);
                localStorage.setItem('user_role', data.user.role);
                localStorage.setItem('user_permissions', data.user.permissions);
                return window.location = '/agreements'
            })
            .catch((err) => { setErrorCall(true) });
    }

    const saveOnEnter = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            loginFunc()
        }
    }

    return (
        <Container className='containerLogin'>
            <h4>Notaría</h4>
            <div>
                <Table borderless>
                    <tbody>
                        <tr>
                            <th>Usuario</th>
                            <th><input onChange={(e) => { setUsername(e.target.value); errorCall && setErrorCall(false) }} /></th>
                        </tr>
                        <tr>
                            <th>Password</th>
                            <th>
                                <input onKeyUp={(e) => saveOnEnter(e)} onChange={(e) => { setPassword(e.target.value); errorCall && setErrorCall(false) }} type={blindPassword && "password"} />
                            </th>
                            <th>
                                <Button className='blindBtn' onClick={() => setBlindPassword(!blindPassword)}>
                                    {blindPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                </Button>
                            </th>
                        </tr>
                        <tr>
                            <th className='errorText'>
                                {errorCall && <label>Usuario o password incorrectos</label>}
                            </th>

                        </tr>
                    </tbody>
                </Table>
                <div className={errorCall ? 'btnContainerSession' : ''}>
                    <Button onClick={() => loginFunc()}>Iniciar Sesión</Button>
                </div>
            </div>
            <NotificationContainer />
        </Container>
    );
}

export default LoginPage;
